import React, { useState } from "react";
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Collapse,
  IconButton,
  CardHeader,
  Avatar,
  styled,
  Divider,
} from "@mui/material";
import styles from "./Notes.module.css";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ExpandMore } from "@mui/icons-material";
import { red } from "@mui/material/colors";
function Notes() {
  const [notes, setNotes] = useState([]);
  const [noteText, setNoteText] = useState("");
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const addNote = () => {
    if (noteText.trim() !== "") {
      setNotes([...notes, noteText]);
      setNoteText("");
    }
  };

  const removeNote = (index) => {
    const updatedNotes = notes.filter((_, i) => i !== index);
    setNotes(updatedNotes);
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  const buttonStyle = {
    backgroundColor: "#130f26",
    border: "2px solid #130f26",
    width: "100%",
    fontWeight: "500",
    fontSize: "13px",
    color: "#fff",
    boxShadow: "none",
    borderRadius: "7px",
    "&:hover": {
      borderRadius: "7px",
      fontSize: "13px",
      fontWeight: "500",
      boxShadow: "none",
      border: "2px solid #130f26",
      backgroundColor: "#fff",
      color: "#130f26", // Background color on hover
    },
  };
  const dummyArray = [
    { id: 1, name: "Item 1" },
    { id: 2, name: "Item 2" },
    { id: 3, name: "Item 3" },
    { id: 4, name: "Item 4" },
    { id: 5, name: "Item 5" },
  ];
  return (
    <div className={styles.notesContainer} style={{ fontFamily: "Poppins" }}>
      {dummyArray.map((ele, i) => (
        <Card sx={{ maxWidth: 345, borderRadius: "12px" }} key={i}>
          <CardHeader
            avatar={
              <Avatar
                sx={{
                  bgcolor: red[500],
                  width: "30px",
                  height: "30px",
                  fontSize: "18px",
                }}
                aria-label="recipe"
              >
                R
              </Avatar>
            }
            action={
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ marginTop: "14px", fontSize: "13px" }}
              >
                2 hr ago
              </Typography>
            }
            titleTypographyProps={{
              style: {
                fontWeight: 500, // Set the desired font weight here
              },
            }}
            title="Jishnu Hari"
            //   subheader="September 14, 2016"
          />
          <Divider />
          <CardContent>
            <Typography
              variant="h6"
              color="text.primary"
              sx={{ marginBottom: "5px" }}
            >
              5 tips to create a modern app UI Design
            </Typography>
            <Typography variant="body2" color="text.secondary">
              This impressive paella is a perfect party dish and a fun meal to
              cook together with your guests. Add 1 cup of frozen peas along
              with the mussels, if you like.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Button
              variant="contained"
              className="read-more-cta"
              sx={buttonStyle}
            >
              Read More
            </Button>
            {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore> */}
          </CardActions>
        </Card>
      ))}
    </div>
  );
}

export default Notes;
