import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useLocation, useHistory, useNavigate } from "react-router-dom";
import {
  useTheme,
  Pagination,
  PaginationItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import Client from "../../api/client";
import Helper from "../../utils/helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GoInfo } from "react-icons/go";
import { toast } from "react-toastify";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { AuthContext } from "../../Context/UserContext";
import { FaInfoCircle } from "react-icons/fa";
import Modal from 'react-responsive-modal';
import { _getCourseDetails,getusertestdata } from "../../services/courseService";
import styles from "./CourseDetails.module.css";

function MCQScreen(props) {
  const location = useLocation();
  const navigate = useNavigate();
  // const { setLoading } = useContext(AuthContext);
  const contestDetails = location?.state;

  const [isSelectedAns, setSelectedAns] = useState(false);
  const [isSubmit, setSubmit] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState();
  const [selectedOption, setSelectedOption] = useState(0);
  const [correct, setCorrect] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [loading, setLoading] = useState(true); // Added loading state

  const UserResponse = useRef([]);
  const [isGameFinished, setisGameFinished] = useState(false);
  const AnsweredTime = useRef(0);
  const [count, setCount] = useState(60 * 60);

  const roomId = data?._id;

  useEffect(() => {
     console.log('@@@vikash' , contestDetails)
    //  console.log('@@@v' ,  props)

     let id = contestDetails.item.id
     _getusertestdatas(id)
   
    // if (location.state?.submited) {

    //   let id = contestDetails.item.id
    //   setSubmitted(true);
    // }
    // setQuestions(location?.state?.questions);
    setSelectedQuestion(location?.state?.questions?.[0]?.id);
  }, [location?.state]);

  useEffect(() => {
    const onBackPress = () => {
      if (window.confirm("Hold on!\nAre you sure you want to exit?")) {
        return true;
      } else {
        return false;
      }
    };

    window.addEventListener("beforeunload", onBackPress);

    return () => {
      window.removeEventListener("beforeunload", onBackPress);
    };
  }, []);
  const handleFinishExam = () => {
    // Handle any logic needed before finishing the exam
    // const courseDetail= _getCourseDetails()
    // console.log(courseDetail)
    navigate(-1);

  };
  const _getusertestdatas =async (id)=>{
  
    let res  = await getusertestdata(id).then((s)=>{
      if (s.status == 200){
      console.log("_getusertestdata in status ",s.data.submited)
      let usersubmitted = s.data.submited
      console.log("_getusertestdata in status usersubmitted ",usersubmitted)
    setQuestions(s.data.questions)
    setSubmitted(usersubmitted)
    // if(s.data.submitted == true){
    //   console.log("_getusertestdata inside ",s.data.submited)
    //   setSubmitted(true)
    // }
     console.log("submitted",submitted)
    setLoading(false)


      // return s
      }
    })
    
  }

  const _updateUserResult = async () => {
    const datas = {
      userresponse: UserResponse?.current,
      score: score,
      testId: contestDetails.item.package_test_mapping.TestId,
      packageId: contestDetails.item.package_test_mapping.packageId,
    };
    try {
      setLoading(true);
      const res = await Client.post("/submitMcqTestAnswer", datas);
      if (res?.status && res?.data) {
        setLoading(false);
        setShowModal(true);
        // navigate(-1);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const isCorrectAnswer = (item, index) => {
    try {
      if (!item || !item.score) return false;

      if (item?.score?.selected_answer == null) return false;

      return (
        item.score.selected_answer == item.correctOption &&
        item.score.selected_answer == index + 1
      );
    } catch (error) {
      console.error("Error while evaluating the condition:", error);
      return false;
    }
  };

  const validateAnswer = (skip, Save, Review) => {
    const currentQuestion = questions[currentQuestionIndex];
    const CorrectAnswerOption = parseInt(currentQuestion.correctOption);

    const m = {
      questionId: currentQuestion.id,
      correntAnswer: CorrectAnswerOption,
      SelectedAnswer: selectedOption || 0,
      time: AnsweredTime.current,
      skiped: skip,
      Save: Save,
      Review: Review,
    };

    const existingIndex = UserResponse.current.findIndex(
      (que) => que.questionId == currentQuestion.id
    );

    if (CorrectAnswerOption == selectedOption) {
      setScore(score + 1);
    }

    if (existingIndex !== -1) {
      UserResponse.current[existingIndex] = m;
    } else {
      UserResponse.current.push(m);
    }

    if (currentQuestionIndex == questions.length - 1) {
      setCount(0);
      setTimeout(() => {
        _updateUserResult();
      }, 1000);
      setisGameFinished(true);
    }

    setTimeout(() => {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAns(false);
      setSelectedOption("");
      setSubmit(false);
    }, 1000);
  };
  if (loading) {
   
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }



  return (
    <div
      style={{
        backgroundColor: submitted ? "#fff" : "#364954",
        padding: "30px",
        height: "auto",
        fontFamily: "Poppins",
      }}
    >
     
      <div className="header">{/* Header content */}</div>
      {submitted ? (
        questions && questions?.length &&
        questions.map((que, i) => (
          <div
            className="question-details"
            style={{
              color: "#1f5972",
              width: "100%",
              margin: "0 auto",
              marginTop: "20px",
              padding: "10px",
              borderRadius: "8px",
              boxShadow: "0 0 8px 1px #1f59724d",
            }}
          >
            <div
              style={{
                borderRadius: "12px",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <div
                className="question-info"
                style={{
                  fontSize: "18px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span className="question-number">Question {i + 1}</span>
                {!que?.score?.selected_answer ||
                que?.score?.selected_answer == 0 ? (
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: "13px",
                      color: "red",
                    }}
                  >
                    Skipped
                  </span>
                ) : null}
              </div>
              <div
                className="question-text"
                style={{ paddingLeft: "18px" }}
                dangerouslySetInnerHTML={{ __html: que?.question_desc }}
              ></div>
            </div>
            <div className="question-options">
              {que?.option?.map((option, index) => (
                <div
                  key={index}
                  style={{
                    borderRadius: "12px",
                    padding: "10px",
                    marginBottom: "10px",
                    cursor: "pointer",
                    paddingLeft: "18px",
                    backgroundColor:
                      isCorrectAnswer(que, index) ||
                      que?.correctOption == index + 1
                        ? "#99ff99"
                        : que?.score?.selected_answer == index + 1
                        ? "#ff00007f"
                        : "#fff",
                    justifyContent: "center",
                    border:
                      isCorrectAnswer(que, index) ||
                      que?.correctOption == index + 1
                        ? "2px dashed #fff"
                        : que?.score?.selected_answer == index + 1
                        ? "2px dashed #ff00007f"
                        : "2px dashed #808080",
                  }}
                  dangerouslySetInnerHTML={{ __html: option?.option }}
                ></div>
              ))}
            </div>
            <div className="explanation">
              <Accordion elevation={0}>
                <AccordionSummary
                  sx={{ color: "#c1b0ee", padding: "5px" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <span
                    style={{
                      paddingTop: "2px",
                      marginRight: "6px",
                      fontSize: "18px",
                    }}
                  >
                    <GoInfo />
                  </span>{" "}
                  Explanation
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    color: "#1f5972",
                    fontSize: "14px",
                    paddingLeft: "18px",
                  }}
                  dangerouslySetInnerHTML={{ __html: que?.explanation }}
                ></AccordionDetails>
              </Accordion>
            </div>
          </div>
        ))
      ) : (<>
{showModal ? (
  <div>
    <Modal
      open={showModal}
      onClose={handleFinishExam}
      center
      styles={{
        modal: {
          width: "90%",
          maxWidth: "400px",
          height: "auto",
          borderRadius: "12px",
          padding: "20px",
          textAlign: "center",
          backgroundColor: "#364954",
          position: 'relative',
          left: '50%', // Center horizontally
          transform: 'translateX(-50%)', // Center horizontally
        },
        closeIcon: { // Style for the close icon
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: '#fff',
          cursor:'pointer',
        },
      }}
    >
      <div style={{color : 'white'}}>
        <h2>Your Score</h2>
        <p>{`Your score: ${score}/${ questions ? questions?.length : null}`}</p>
        <button style={{ marginTop: '20px', padding: '10px 20px', borderRadius: '8px', backgroundColor: '#1f5972', color: '#fff', border: 'none', cursor: 'pointer' }} onClick={handleFinishExam}>Finish</button>
      </div>
    </Modal>
  </div>

 ) : (
        <div>
          <div
            className="question-list"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              marginBottom: "25px",
              margin: "0 auto",
              width: "100%",
            }}
          >


            { questions && questions.length && (
              <Pagination
                sx={{
                  marginBottom: "10px",
                }}
                count={questions.length}
                variant="outlined"
                page={currentQuestionIndex + 1}
                renderItem={(item) => {
                  return (
                    <PaginationItem
                      sx={{
                        color: UserResponse.current.some(
                          (que) =>
                            que.questionId == questions[item.page - 1]?.id &&
                            que.skiped
                        )
                          ? "red"
                          : UserResponse.current.some(
                              (que) =>
                                que.questionId ==
                                questions[item.page - 1]?.id && que.Save
                            )
                          ? "#116D6E"
                          : UserResponse.current.some(
                              (que) =>
                                que.questionId ==
                                questions[item.page - 1]?.id && que.Review
                            )
                          ? "#EA906C"
                          : "#fff",
                        bgcolor: UserResponse.current.some(
                          (que) =>
                            que.questionId == questions[item.page - 1]?.id &&
                            que.skiped
                        )
                          ? "#FCAEAE"
                          : UserResponse.current.some(
                              (que) =>
                                que.questionId ==
                                questions[item.page - 1]?.id && que.Save
                            )
                          ? "#DDF7E3"
                          : UserResponse.current.some(
                              (que) =>
                                que.questionId ==
                                questions[item.page - 1]?.id && que.Review
                            )
                          ? "#F4E0B9"
                          : "#EEE2DE2f",
                      }}
                      {...item}
                      onClick={() => {
                        setCurrentQuestionIndex(item.page - 1);
                        setSelectedOption(
                          UserResponse.current[item.page - 1]?.SelectedAnswer
                        );
                      }}
                    />
                  );
                }}
                shape={"rounded"}
              />
            )}
          </div>
          <div
            className="question-details"
            style={{ color: "#fff", width: "100%", margin: "0 auto" }}
          >
            <div
              style={{
                backgroundColor: "#1f5972",
                borderRadius: "12px",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
            <div className="question-info">
            <span className="question-number">
              Question  {Math.min(currentQuestionIndex + 1, questions.length)}/{questions.length}
            </span>
          </div>
              <div
                className="question-text"
                style={{
                  paddingLeft: "18px",
                }}
                dangerouslySetInnerHTML={{
                  __html: questions[currentQuestionIndex]?.question_desc,
                }}
              ></div>
            </div>
            <div className="question-options">
              {questions[currentQuestionIndex]?.option?.map((option, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#1f5972",
                    borderRadius: "12px",
                    padding: "10px",
                    paddingLeft: "18px",
                    marginBottom: "10px",
                    cursor: "pointer",
                    border:
                      selectedOption === index + 1 ? "2px solid #fff" : "none",
                    borderStyle:
                      selectedOption === index + 1 ? "dotted" : "none",
                  }}
                  onClick={() => setSelectedOption(index + 1)}
                  dangerouslySetInnerHTML={{ __html: option?.option }}
                ></div>
              ))}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              margin: "0 auto",
              display: "flex",
              marginTop: "25px",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <button
              style={{
                backgroundColor: "#feaeb0",
                color: "#f01419",
                borderRadius: "8px",
                fontWeight: 500,
                padding: "8px",
                border: "none",
                cursor:'pointer',
              }}
              onClick={() => {
                validateAnswer(true, false, false);
              }}
              disabled={isSelectedAns}
            >
              Skip
            </button>
            <button
              style={{
                backgroundColor: "#ddf7e3",
                color: "#347f7c",
                borderRadius: "8px",
                fontWeight: 500,
                padding: "8px",
                border: "none",
                cursor:'pointer',

              }}
              onClick={() => {
                if (selectedOption != "") {
                  validateAnswer(false, true, false);
                } else {
                  toast.warning("Please select an option");
                }
              }}
              disabled={isSelectedAns}
            >
              Save and Next
            </button>
            <button
              style={{
                backgroundColor: "#f5e0b9",
                color: "#ed936d",
                borderRadius: "8px",
                fontWeight: 500,
                padding: "8px",
                border: "none",
                cursor:'pointer',

              }}
              onClick={() => {
                if (selectedOption != "") {
                  validateAnswer(false, false, true);
                } else {
                  toast.warning("Please select an option");
                }
              }}
              disabled={isSelectedAns}
            >
              Review and Next
            </button>
          </div>
        </div>

        )

}
        </>
      )}
    </div>
  );
}

export default MCQScreen;
