import axios from "axios";
import Client from "../api/client";
import Helper from "../utils/helper";
import { Config } from "../api/config";
import React, { useContext, useEffect, useState,useRef } from "react";

export const _getAllCourses = async () => {
  return Client.get("/getallpackagesforhome?page=1");
};

export const _getAllCoursesNew = async (page = 1,limit,search) => {
  return Client.get(`/getallpackagesforhomeNew?page=${page}&limit=${limit}${search ? `&search=${search}` :""}`);
};

export const _getCoursePackages = async () => {
  try {
    return axios.get(Config.API_URL + "/getallpackagesforhome?page=1", {
      headers: {
        Authorization: `Bearer ${Helper.getLocalStorageItem("users")?.token}`,
      },
    });
    // console.log("🚀 ~ file: client.js:59 ~ const_getCoursePackages= ~ res:", res.data)
  } catch (err) {
    console.log(
      "🚀 ~ file: courseService.js:12 ~ const_getCoursePackages= ~ err:",
      err
    );
  }
};
export const _getCourseDetails = async (id , user) => {

  if (user?.token ) {
  return Client.get(`/getpackagedetailsbyid/` + id);
    
  }else {
    return Client.get(`/opengetpackagedetailsbyid/` + id);

  }
};
export const _getuserCourseDetails = async (id) => {
  const res = await axios.get(
    Config.API_URL + `/getuserpackagesubscriptionbyid/` + id,
    {
      headers: {
        Authorization: `Bearer ${Helper.getLocalStorageItem("users")?.token}`,
      },
    }
  );
  return res;
};

export const getUserTestData = async (id) => {
  const res = await axios.get(Config.API_URL + `/getestdatabyid/` + id, {
    headers: {
      Authorization: `Bearer ${Helper.getLocalStorageItem("users")?.token}`,
    },
  });
  return res;
};

export const getMyCourses = async (state, setState) => {
  try {
    const res = await axios.get(Config.API_URL + "/getusercourseinfo", {
      headers: {
        Authorization: `Bearer ${Helper.getLocalStorageItem("users")?.token}`,
      },
    });
    // console.log("🚀 ~ file: client.js:59 ~ const_getCoursePackages= ~ res:", res.data)
    if (res.status == 200) {
      // console.log('@@usercourse',  res.data);
      setState(res.data);
    }
  } catch (err) {
    console.log(
      "🚀 ~ file: courseService.js:12 ~ const_getCoursePackages= ~ err:",
      err
    );
  }
};
export const getusertestdata = async (id) => {
  const res = await axios.get(Config.API_URL + `/getestdatabyid/` + id, {
    headers: {
      Authorization: `Bearer ${Helper.getLocalStorageItem("users")?.token}`,
    },
  });
  console.log("getusertestdata", res);
  return res;
};
