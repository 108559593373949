import React, { useEffect, useState } from "react";
import Client from "../api/client";
import { Config } from "../api/config";
const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState({
    token: null,
  });
  const [loading, setLoading] = useState(false);
  const getToken = async (setUser) => {
    let users = localStorage.getItem("users"); // Replace with your token retrieval logic
    if (!users) {
      return;
    }
    try {
      const res = await Client.get(Config.API_URL_Pay + "/getusersubscription");

      if (res.status == 200) {
        setUser({ ...JSON.parse(users), subscribed: true });
      }
    } catch (error) {
      setUser(JSON.parse(users));
      console.log(error);
    }
    // Fetch the token and update
  };
  const _getUserSubscription = async () => {
    try {

      const res = await Client.get("/getusersubscription",{ baseURL: Config.API_URL_Pay,});
      if (res.status == 200) {
        setUser({
          ...user,
          subscribed: true,
          isFreePlan: res?.data?.isFreePlan ? true : false,
          planId: res?.data?.planId ,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getToken(setUser);
    // setAuthToken(user.token)
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, setUser, _getUserSubscription, loading, setLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
