import { Stack, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import coinIcon from "../Assets/goldcoin.webp";
import { Check } from "@mui/icons-material";

export default function CartCard(props) {
  return (
    <Stack
      padding="8px"
      border={props.selected ? "3px solid #4A98C5" : "1px solid #E3E3E3"}
      borderRadius={"15px"}
      backgroundColor="#fff"
      onClick={props.onClick}
      margin={3}
    >
      <div style={{ position: "relative" }}>
        {props?.data?.imagePath ? (
          <img
            style={{
              display: "block",
              height: 260,
              width: "100%",
              objectFit: "cover",
              marginBottom: 18,
              borderRadius: 15,
              cursor: "pointer",
            }}
            alt="Course Cover"
            src={props.data?.imagePath}
          />
        ) : (
          <img
            style={{
              display: "block",
              height: 260,
              width: "100%",
              objectFit: "cover",
              marginBottom: 18,
              borderRadius: 15,
              cursor: "pointer",
            }}
            alt="Course Cover"
            src="https://images.unsplash.com/photo-1552862750-746b8f6f7f25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGlnaHRidWxifGVufDB8fDB8fHww&w=1000&q=80"
          />
        )}
      </div>

      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom="18px"
      >
        <Typography fontSize={12} fontWeight={500} color="#5F81B3">
          {props?.data?.packageName}
        </Typography>
      </Stack>

      <Typography fontSize={14} fontWeight={600} color="#0E5794">
        {props?.data?.packageDesc?.length > 40
          ? `${props?.data?.packageDesc.slice(0, 40)}...`
          : props?.data?.packageDesc}
      </Typography>
      {props?.data?.packagePrice ? (
        <Typography fontSize={14} fontWeight={700} color="#3E3E3E">
          &#8377; {props?.data?.packagePrice ? props?.data?.packagePrice : 0}{" "}
        </Typography>
      ) : null}
    </Stack>
  );
}
