import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import heroImage from "../../Assets/heroImage.png";
import styles from "./Homepage.module.css";
import { BsArrowRight, BsArrowUpRightSquareFill } from "react-icons/bs";
import {
  PiGraduationCap,
  PiUsersLight,
  PiVideoCameraLight,
} from "react-icons/pi";
import differenceImage from "../../Assets/differenceImage.png";
import Slider from "react-slick";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import logo from "../../Assets/logo.png";
import { CiFacebook } from "react-icons/ci";
import { FiTwitter, FiInstagram } from "react-icons/fi";
import { AiOutlineBehance } from "react-icons/ai";
import maatsaab from "../../Assets/maatsaab.png";
import interviewPrepration from "../../Assets/interviewPrepration.png";
import GaugeChart from "react-gauge-chart";
import CourseCard from "../../Components/CourseCard";
import {
  _getAllCourses,
  _getCoursePackages,
} from "../../services/courseService";
import { CoursePackageContext } from "../../Context/CoursesContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/UserContext";
import Carousel from "react-material-ui-carousel";
import ReactStars from "react-stars";
import FeatureItem from '../../Components/FeatureItem'
import JoinSection from "../../Components/JoinSection";
import TestimonialsSection from '../../Components/TestimonialsSection';
export default function Homepage() {
  const [courses, setCourses] = useState([]);
  const { user } = useContext(AuthContext);
  const { CoursePackage, setCoursePackage } = useContext(CoursePackageContext);
  const updateAllCourses = async () => {
    try {
      const res = await _getCoursePackages();
      if (res.status == 200) {
        setCoursePackage(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {

    if (!CoursePackage?.length && user) {

      updateAllCourses();
    }
  }, []);

  const testimonials = [
    {
      name: "Abhinav SIWACH",
      rank: "AIR-12 | IAS 2022",
      review:
        "IAS Skool provided personalized guidance, mock tests, and valuable study materials, which were instrumental in my preparation. I recommend this platform to all UPSC aspirants",
      image_url:
        "https://iasskool-wesbite.s3.ap-south-1.amazonaws.com/testimonials/Abhinav-siwach.png",
      rating: 5,
    },
    {
      name: "Kritika Goyal",
      rank: "AIR-14 | IAS 2022",
      review:
        "IAS Skool acted as my mentor, providing continuous motivation and guidance throughout my UPSC journey.This platform truly made my preparation enjoyable and fruitful",
      rating: 5,
      image_url:
        "https://iasskool-wesbite.s3.ap-south-1.amazonaws.com/testimonials/Kritika_goyal.png",
    },
    {
      name: "Swati Sharma",
      rank: "AIR-15 | IAS 2022",
      review:
        "Personalized learning at its best. Aicoach unlocked my potential. I highly recommend it to anyone looking for a comprehensive and effective exam prep solution.",
      rating: 5,
      image_url:
        "https://iasskool-wesbite.s3.ap-south-1.amazonaws.com/testimonials/Swati-Sharma.png",
    },
    {
      name: "Gunjita Agrawal",
      rank: "AIR-26 | IAS 2022",
      review:
        "The mock interviews, DAF tests conducted were a game-changer in my interview preparation. Thanks to this platform, I entered the interview room with confidence. The platform transformed my exam preparation into a smart and efficient process",
      rating: 5,
      image_url:
        "https://iasskool-wesbite.s3.ap-south-1.amazonaws.com/testimonials/gunjita-Agrawal.png",
    },
    {
      name: "Priyanshi Garg",
      rank: "AIR-31 | IAS 2022",
      review:
        "IAS Skool is a complete package that exceeded my expectations. The personalized learning experience,comprehensive study materials, and AI-powered analytics provided the perfect combination to optimize my exam preparation",
      rating: 5,
      image_url:
        "https://iasskool-wesbite.s3.ap-south-1.amazonaws.com/testimonials/Priyansha-garg.png",
    },
  ];
  const navigate = useNavigate();
  return (
    <Fragment>
      <Stack className="stack-padding" padding="20px 60px" flexDirection="row" gap="20px" marginTop={3}>
        <Stack justifyContent="space-between">
          <Typography color="#676061" fontSize={44} fontWeight={800}>
            Revolutionize UPSC Journey with{" "}
            <Typography display="inline" color="#6065DA" fontSize={44} fontWeight={800}>
              AI-Powered
            </Typography>{" "}
            Test Series, Lectures, and Tutors
          </Typography>

          <Typography fontSize={16} color="#B0B0D1" marginTop="20px">
            The education industry is witnessing a revolution with the advent of
            AI-powered innovative learning providers that offer affordable,
            quality solutions for students, teachers, and parents.
          </Typography>

          <Button
  sx={{
    borderRadius: 50,
    height: "50px",
    fontSize: 12,
    padding: "0px 40px",
    boxShadow: "none",
    backgroundColor: "#6065DA",
    marginTop: 3,
    textTransform: "none",
    width: "fit-content",
  }}
  variant="contained"
  onClick={() => {
    const featuresSection = document.getElementById("mostPopularFeatures");
    if (featuresSection) {
      featuresSection.scrollIntoView({
        behavior: "smooth",
        block: "center", // Vertical alignment
        inline: "center", // Horizontal alignment
      });
    }
  }}
>
  Explore Value proposition
</Button>


        </Stack>

        <Box 
          className="hero-image"
          sx={{
            width: "100%",
            display: { xs: "none", sm: "block" }, // Hide on xs (extra small) screens, display on other screen sizes
          }}
         >
  <video
    height="auto"
    autoPlay
    loop
    muted
    className="w-full"
    style={{ maxWidth: "100%" }}
  >
    <source
      src="https://res.cloudinary.com/dqemlno5f/video/upload/c_scale,q_auto:good,vc_h264,w_928/v1684064766/a_koch_1_ltxv4l.mp4"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
</Box>


      </Stack>

      {/* Popular Courses Section */}
      <Stack marginTop={8} padding="0 60px">
        <Stack flexDirection="row" gap="8px" alignItems="flex-end">
          <Typography fontSize={30} fontWeight={600} color="#575757">
            Popular
          </Typography>
          <Typography fontSize={30} fontWeight={600} color="#6065DA">
            Courses
          </Typography>
        </Stack>

        <Grid container spacing={2} justifyContent="left">
          {CoursePackage?.length ? (
            CoursePackage.map((ele, ind) => (
              <Grid key={ind} item xs={12} sm={6} md={4} lg={4}>
                <CourseCard
                  data={ele}
                  navigate={"/course/details/" + ele.id}
                />
              </Grid>
            ))
          ) : (
            <Typography>No courses available</Typography>
          )}
        </Grid>


        <Button
          sx={{
            borderRadius: 50,
            height: "50px",
            fontSize: 12,
            padding: "0px 40px",
            boxShadow: "none",
            backgroundColor: "#6065DA",
            margin: "10px auto",
            textTransform: "none",
            width: "fit-content",
          }}
          onClick={() => navigate("/courses")}
          variant="contained"
        >
          Explore all courses
        </Button>
      </Stack>

      <Stack marginTop={14} padding="0 60px" marginBottom={"120px"} 
        id="mostPopularFeatures" >
        <Stack
          flexDirection="row"
          gap="8px"
          alignItems="center"
          justifyContent={"center"}
          marginBottom={4}
        >
          <Typography fontSize={30} fontWeight={600} color="#575757">
            Most Popular
          </Typography>
          <Typography fontSize={30} fontWeight={600} color="#6065DA">
            Features
          </Typography>
        </Stack>

        <Grid container spacing={2} justifyContent="center">
          {/* Grid items for features */}
          <Grid item xs={12} sm={6} md={3}>
            {/* Feature item */}
            <Stack
              flexDirection={"row"}
              justifyContent={"left"}
              alignItems={"center"}
            >
              <BsArrowUpRightSquareFill size={25} color="#6065DA" />

              <Typography margin={'0px 10px'} fontSize={16} color="#676061">
                MaatSaab
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* Feature item */}
            <Stack
              flexDirection={"row"}
              justifyContent={"left"}
              alignItems={"center"}
            >
              <BsArrowUpRightSquareFill size={25} color="#6065DA" />

              <Typography margin={'0px 10px'} fontSize={16} color="#676061">
                AI Evalutor
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* Feature item */}
            <Stack
              flexDirection={"row"}
              justifyContent={"left"}
              alignItems={"center"}
            >
              <BsArrowUpRightSquareFill size={25} color="#6065DA" />

              <Typography margin={'0px 10px'} fontSize={16} color="#676061">
                Community connect
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {/* Feature item */}
            <Stack
              flexDirection={"row"}
              justifyContent={"left"}
              alignItems={"center"}
            >
              <BsArrowUpRightSquareFill size={25} color="#6065DA" />

              <Typography margin={'0px 10px'} fontSize={16} color="#676061">
                Personalized Notes
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>


      <Stack
        maxWidth={"70%"}
        margin={"0 auto"}
        marginBottom={8}
        padding="14px 0"
        border={"1px solid #e6e6e6"}
        borderRadius={8}
      >
        <Stack
          marginBottom={-8}
          padding={"0px 40px"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack>
            <Typography color="#002835" fontSize={18} fontWeight={600}>
              MaatSaab Mentoring:
            </Typography>
            <Typography color="#002835" fontSize={16} fontWeight={200}>
              Powered by Human Expertise!
            </Typography>
          </Stack>
          <Typography color="#4A98C5" fontSize={16} fontWeight={600}>
            1k Points
          </Typography>
        </Stack>
        <img style={{ width: "100%" }} src={maatsaab} />
        <Stack padding={"0px 40px"}>
          <Typography marginTop={-8} fontSize={16} color="#929292">
            Empower your learning with our unique mentoring program that
            combines human teachers and AI technology. Benefit from personalized
            guidance and feedback to achieve your academic goals.
          </Typography>
          <Button
            sx={{
              borderRadius: 50,
              height: "50px",
              fontSize: 12,
              marginTop: 2,
              padding: "0px 40px",
              boxShadow: "none",
              backgroundColor: "#1F5972",
              textTransform: "none",
              width: "100%",
            }}
            variant="contained"
            onClick={() => {
              navigate("/maatsaab");
            }}
          >
            Check Now!
          </Button>
        </Stack>
      </Stack>

      <Grid
        container
        sx={{
          backgroundColor: "#F5F0FF",
          padding: "40px",
          alignItems: "center",
          justifyContent: "left",
          textAlign: "left",
        }}
        id="aboutus"
      >
        <Grid item xs={12} md={4}>
          <img style={{ width: "100%", maxWidth: "300px" }} src={differenceImage} />
        </Grid>

        <Grid item xs={12} md={7}>
          <Stack
            sx={{ marginBottom: { xs: "20px", md: "0" } }}
            spacing={2}
            alignItems="flex-start"
          >
            <Typography fontSize={34} fontWeight={600} color="#575757">
              What is our
            </Typography>
            <Typography fontSize={34} fontWeight={600} color="#6065DA">
              difference
            </Typography>
            <Typography fontSize={14} color="#525252" fontWeight={500}>
              AI-Koach's educational platform stands out from others because it
              offers cutting-edge features that utilize artificial intelligence to
              enhance the learning experience for students. These features include
              AI-based evaluation and test series, which can provide more accurate
              and objective assessments of students' knowledge and skills, as well as
              personalized content and lectures that can be tailored to individual
              learning needs and preferences.
            </Typography>
            <Typography fontSize={14} color="#525252" fontWeight={500}>
              Additionally, AI-Koach's platform offers market-relevant skill
              development opportunities that can help students acquire the practical
              knowledge and expertise they need to succeed in today's rapidly evolving
              job market. Overall, AI-Koach's platform is differentiated by its
              innovative approach to education, which leverages advanced technologies
              to provide students with a more effective and engaging learning
              experience.
            </Typography>
          </Stack>

          <Stack
            sx={{ justifyContent: "left" }}
            spacing={2}
            direction={{ xs: "column", md: "row" }}

          >
            <FeatureItem
              icon={<PiGraduationCap color="#fff" size={28} />}
              label="Instructor"
              value="300"
            />
            <FeatureItem
              icon={<PiUsersLight color="#fff" size={28} />}
              label="Students"
              value="20,000+"
            />
            <FeatureItem
              icon={<PiVideoCameraLight color="#fff" size={28} />}
              label="Videos"
              value="30,000+"
            />
          </Stack>
        </Grid>
      </Grid>


      <TestimonialsSection testimonials={testimonials} />


      <JoinSection user={user} navigate={navigate} />

      <Grid container sx={{ padding: 8 }} justifyContent={"space-between"}>
        <Grid item xs={3}>
          <img style={{ height: 45 }} src={logo} />
          <Typography fontSize={12} marginTop={2} color="#B0B0D1">
            An integrated Facility and Utility Management Company has LMS and
            HRIS combined with AI/ML technologies . The aim is to provide the
            best personalized content, tests, and lectures based on the
            student's performance. We specialize in conducting tests, providing
            exam-relevant content and providing personalized lectures for most
            of the exams . It also includes student mentorship. In the last five
            years, the team has successfully served more than 1,000 students.
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography
            marginBottom={4}
            color="#676060"
            fontSize={18}
            fontWeight={500}
          >
            Quick Links
          </Typography>
          <Stack gap="10px">
            <a
              style={{
                color: "#B0B0D1",
                fontSize: "14px",
              }}
              href="/aboutus"
            >
              About
            </a>
            {/* <Link  fontSize={14}>
              About
            </Link> */}
            <Link color="#B0B0D1" fontSize={14} href  ="/contactus">
              Contact Us
            </Link>
            <Link color="#B0B0D1" fontSize={14} href="  /privacy-policy">
              Privacy Policy
            </Link>
            <Link color="#B0B0D1" fontSize={14} href="/terms-and-conditions">
              Terms & Conditions
            </Link>
            <Link color="#B0B0D1" fontSize={14} href="/refund-policy">
              Refund Policy
            </Link>
            <Link color="#B0B0D1" fontSize={14} href="/shipping-policy">
              Shipping Policy
            </Link>
          </Stack>
        </Grid>

        <Grid item xs={3}>
          <Typography
            marginBottom={4}
            color="#676060"
            fontSize={18}
            fontWeight={500}
          >
            Contact us
          </Typography>

          <Stack gap={"10px"}>
            <Typography color="#B0B0D1" fontSize={14}>
              7011-052-485
            </Typography>
            <Typography color="#B0B0D1" fontSize={14}>
              contact@aikoach.com
            </Typography>
            <Typography color="#B0B0D1" fontSize={14}>
              Metro Pillar No. 114,Old Rajinder Nagar, New Delhi, 110060
            </Typography>
            <Typography color="#B0B0D1" fontSize={14}>
              23-B,3rd Floor,Pusa Road,Metro Pillor No. 114 Above Suri Lab,Old
              Rajender Nagar,New Delhi-110060.
            </Typography>
          </Stack>
        </Grid>

        <Grid marginTop={4} marginBottom={4} item xs={12}>
          <Divider />
        </Grid>

        <Grid container justifyContent={"space-between"}>
          <Typography fontSize={14} color={"#B0B0D1"}>
            MACHINEMODE Y22 DOT PRIVATE LIMITED | Copyright 2023 | All Rights
            Reserved
          </Typography>

          <Stack flexDirection={"row"} alignItems={"center"} gap={"20px"}>
            <CiFacebook size={22} cursor={"pointer"} color="#777E90" />
            <FiTwitter size={22} cursor={"pointer"} color="#777E90" />
            <FiInstagram size={22} cursor={"pointer"} color="#777E90" />
            <AiOutlineBehance size={22} cursor={"pointer"} color="#777E90" />
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
}
