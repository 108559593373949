import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Stack,
  Button,
  InputAdornment,
  TextField,
} from "@mui/material";
import { BiSearch } from "react-icons/bi";
import CourseCard from "../../Components/CourseCard";
import styles from "./Courses.module.css";
import { Tabs } from "react-tabs";
import { _getAllCoursesNew } from "../../services/courseService";

const ITEMS_PER_PAGE = 6;

export default function AllCourses() {
  // console.log('@@@ALLuserCour', allCourses);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedCourses, setDisplayedCourses] = useState([]);

  const [totalLimit, setTotalLimit] = useState();

  // console.log('@@' ,CoursePackage )
  const updateAllCourses = async () => {
    try {
      // setLoading(true);
      const res = await _getAllCoursesNew(
        currentPage,
        10,
        searchQuery.toLowerCase()
      );
      
      if (res.status === 200) {
        setTotalLimit(res.data.count);
        // setLoading(false);
        setDisplayedCourses(
          !searchQuery
            ? (prev) => [...prev, ...res?.data?.rows]
            : res?.data?.rows
        );
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (displayedCourses.length === 0) {
      updateAllCourses();
    }
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      updateAllCourses();
    }
  }, [currentPage]);

  useEffect(() => {
    if (searchQuery) {
      updateAllCourses();
    }
  }, [searchQuery]);

  // useEffect(() => {
  //   setFilteredCourses(allCourses);
  // }, [allCourses]);

  // useEffect(() => {
  //   const filtered = allCourses.filter(course =>
  //     course?.packageName?.toLowerCase()?.includes(searchQuery.toLowerCase())
  //   );
  //   setFilteredCourses(filtered);
  //   setCurrentPage(1); // Reset to first page when search query changes
  // }, [allCourses, searchQuery]);

  // useEffect(() => {
  //   const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  //   const endIndex = startIndex + ITEMS_PER_PAGE;
  //   const newDisplayedCourses = filteredCourses?.slice(0, endIndex);
  //   setDisplayedCourses(newDisplayedCourses);
  // }, [currentPage, filteredCourses]);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleSearchChange = (value) => {
    setCurrentPage(1);

    setSearchQuery(value);
  };

  return (
    <Tabs>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        marginBottom={2}
      >
        <Grid item xs={12} md={6}>
          <Typography fontSize={22} color="#002835" display={"inline"}>
            Take a Look into All Courses
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BiSearch size={24} />
                </InputAdornment>
              ),
            }}
            sx={{ borderWidth: 0, width: "100%" }}
            placeholder="Search courses..."
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {displayedCourses?.map((course, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <CourseCard
              courseScreen={true}
              data={course}
              navigate={`/course/details/${course.id}`}
            />
          </Grid>
        ))}
      </Grid>
      {totalLimit > displayedCourses?.length && (
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          marginTop={2}
        >
          <Button variant="contained" onClick={handleLoadMore}>
            Load More
          </Button>
        </Stack>
      )}
      {displayedCourses?.length === 0 && (
        <Typography className={styles.noCoursesMessage}>
          No courses found.
        </Typography>
      )}
    </Tabs>
  );
}
