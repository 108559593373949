import React from "react";
import ReactPlayer from "react-player";

function VideoPlayer({ src, width, height }) {
  return (
    <div style={{ width: width, height: height }}>
      <ReactPlayer url={src} controls={true} width={width} height={height} />
    </div>
  );
}

export default VideoPlayer;
