export const Config = {
  API_URL: "https://65.2.25.127.nip.io/api/v1",
  ENV: "DEV",
  API_URL_Pay: "https://13.200.163.30.nip.io",
 razorapikey: "rzp_live_877XM6a8F1iozS"
};
// export const Config = {
//   API_URL: " http://localhost:8000/api/v1",
//   ENV: "DEV",
//   API_URL_Pay: " http://localhost:5000",
//   razorapikey: "rzp_live_877XM6a8F1iozS"
// };