import React, { useContext, useEffect, useState } from "react";
import { _getCoursePackages, getMyCourses, _getAllCourses } from "../services/courseService";
import { AuthContext } from "./UserContext";

const CoursePackageContext = React.createContext();

const CoursePackageProvider = ({ children }) => {
  const [CoursePackage, setCoursePackage] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const { user, setUser } = useContext(AuthContext);
  // console.log("🚀 ~ file: CoursesContext.js:8 ~ CoursePackageProvider ~ CoursePackage:", CoursePackage)

  const updateCourses = async (enrolled = false) => {
    try {
      const res = await _getAllCourses();
      if (res.status == 200) {
        setCoursePackage(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user?.token) {
      getMyCourses(userCourses, setUserCourses);
    }
  }, [user?.token]);
  useEffect(() => {
    updateCourses();
  }, [userCourses]);

  return (
    <CoursePackageContext.Provider
      value={{ CoursePackage, setCoursePackage, userCourses, setUserCourses }}
    >
      {children}
    </CoursePackageContext.Provider>
  );
};

export { CoursePackageContext, CoursePackageProvider };
