import React from "react";

const ShippingPolicy = () => {
  return (
    <div>
      <p>Shipping &amp; Delivery Policy</p>
      <br />
      <p>Last updated on Jul 18th 2023</p>
      <br />
      <p>
        For International buyers, orders are shipped and delivered through
        registered international courier companies and/or International speed
        post only. For domestic buyers, orders are shipped through registered
        domestic courier companies and /or speed post only. Orders are shipped
        within 8+ days or as per the delivery date agreed at the time of order
        confirmation and delivering of the shipment subject to Courier Company /
        post office norms. MACHINEMODE Y22 DOT PRIVATE LIMITED is not liable for
        any delay in delivery by the courier company / postal authorities and
        only guarantees to hand over the consignment to the courier company or
        postal authorities within 8+ days from the date of the order and payment
        or as per the delivery date agreed at the time of order confirmation.
        Delivery of all orders will be to the address provided by the buyer.
        Delivery of our services will be confirmed on your mail ID as specified
        during registration. For any issues in utilizing our services you may
        contact our helpdesk on or contact@iasskool.in
      </p>
      <br />
    </div>
  );
};

export default ShippingPolicy;
