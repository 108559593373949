import React from "react";
import { Grid, Stack, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const JoinSection = ({ user, navigate }) => {
  return (
    <Stack sx={{ backgroundColor: "#F7F3FF" }} padding={4}>
      <Grid
        container
        sx={{
          width: "100%",
          maxWidth: "960px",
          margin: "0 auto",
          alignItems: "center",
          justifyContent: "center",
        }}
        spacing={2}
      >
        <Grid item xs={12} md={8}>
          <Typography
            variant="h4"
            fontWeight={600}
            color="#5A5A5A"
            marginBottom={2}
          >
            Join our{" "}
            <Typography 
            fontWeight={600}
            variant="h4"

             component="span" color="#6065DA">
              world's largest
            </Typography>{" "}
            learning platform today
          </Typography>
          <Typography fontSize={16} color="#5A5A5A" marginBottom={4}>
            Start learning by registering and get a 30-day free trial
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            sx={{
              borderRadius: 50,
              height: "50px",
              fontSize: 12,
              padding: "0px 40px",
              boxShadow: "none",
              backgroundColor: "#6065DA",
              textTransform: "none",
              width: "100%",
            }}
            onClick={() => {
              user?.token ? navigate("/courses") : navigate("/login");
            }}
          >
            Join as student
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default JoinSection;
