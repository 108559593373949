// import AsyncStorage from '@react-native-community/async-storage';
import axios from "axios";
import { Config } from "./config";
import React, { useContext, useEffect, useRef, useState } from "react";
// import moment from 'moment';
// import { _BugsnagNotify } from './utils/Bugsnag';
// import messaging from '@react-native-firebase/messaging';
// import NetInfo from "@react-native-community/netinfo";
// import Snackbar from 'react-native-snackbar';
// import JailMonkey from 'jail-monkey'
// import Sound from 'react-native-sound'

import Helper from "../utils/helper";
let authToken;

export const setAuthToken = (token) => {
  authToken = token;
};

const handleError = ({ message, data, status }) => {
  console.log("Error:", { message, data, status });
  return { message, data, status };
};

const Client = axios.create({
  baseURL: Config.API_URL,
  timeout: 100000,
});


Client.defaults.headers.common["Content-Type"] = "application/json";

Client.interceptors.request.use(
  async (config) => {
    if (!authToken) {
      console.log("in auth");
      let user = Helper.getLocalStorageItem("users");
      if (user?.token) {
        config.headers["Authorization"] = `Bearer ${user?.token}`;
        authToken = user?.token;
      }
    } else {
      console.log("in async");
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    // console.log("🚀 ~ file: client.js:53 ~ Client.interceptors.request.use ~ config:", config.headers)

    // Set loading state to true before sending the request
    // if (loadingOverlay) loadingOverlay.style.display = 'flex';
    return config;
  },
  (error) => {
    // Handle request error
    // if (loadingOverlay) loadingOverlay.style.display = 'none';
    const errorMessage = error.response.data.message;
    const errorStatus = error.response.status;
    const errorData = error.response.data;

    return Promise.reject(
      handleError({
        message: errorMessage,
        data: errorData,
        status: errorStatus,
      })
    );
  }
);

Client.interceptors.response.use(
  (response) => {
    // Set loading state to false after receiving the response
    // if (loadingOverlay) loadingOverlay.style.display = 'none';
    return response;
  },
  (error) => {
    // Handle response error
    console.log(error);
    // if (loadingOverlay) loadingOverlay.style.display = 'none';
    const errorMessage = error.response?.data?.error?.message;
    const errorStatus = error.response.status;
    const errorData = error.response.data;

    return Promise.reject(
      handleError({
        message: errorMessage,
        data: errorData,
        status: errorStatus,
      })
    );
  }
);

export default Client;
