import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useContext, useState } from "react";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";
import Helper from "../../utils/helper";
import { PiSealCheckFill } from "react-icons/pi";
import { toast } from "react-toastify";
import { resendOTP, resetPassword } from "../../services/authService";
import { AuthContext } from "../../Context/UserContext";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { setLoading } = useContext(AuthContext);
  const [step, setStep] = useState("number");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState();
  const resend = async () => {
    try {
      setLoading(true);
      if (!Helper.validPhone(mobileNumber)) {
        return toast.error("Enter valid phone");
      }
      const res = await resendOTP(mobileNumber);
      if (res.status == 200 && res.data?.success == true) {
        setStep("otp");
      }
    } catch (error) {
      return toast.error("Internal Server Error");
    }
    setLoading(false);
  };
  const submitHandler = async () => {
    setLoading(true);
    try {
      if (!otp || otp.toString()?.length != 6) {
        setLoading(false);
        return toast.error("Enter valid OTP");
      }
      if (!password || !password?.length) {
        setLoading(false);
        return toast.error("Password must have atleast 6 characters.");
      }
      if (Helper.validPhone(mobileNumber)) {
        let payload = {
          OTP: otp,
          mobileNumber,
          password,
        };
        const res = await resetPassword(payload);
        if (res.status != 200 || res?.data?.success) {
          setLoading(false);
          return toast.error(res?.data?.message);
        }
        if (res.status == 200) {
          setLoading(false);
          toast.success("Password updated successfully");
          return navigate("login");
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.data?.message || "Internal Server Error");
    }
  };
  return (
    <Stack
      sx={{
        boxShadow:
          "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
        padding: "20px",
        borderRadius: "15px",
        width: "460px",
        margin: "30px auto",
        marginTop: 14,
        minHeight: "calc(100vh - 140px)",
      }}
    >
      {step == "success" && (
        <PiSealCheckFill
          style={{ margin: "14px auto" }}
          size={130}
          color="#18C07A"
        />
      )}
      <Typography
        fontSize={26}
        color="#1F5972"
        fontWeight={600}
        marginBottom={2}
        textAlign={step == "success" ? "center" : "left"}
      >
        {step == "number"
          ? "Forgot Password?"
          : step == "otp"
          ? "Update Password"
          : null}
      </Typography>
      <Typography
        fontSize={16}
        color="#8391A1"
        fontWeight={400}
        marginBottom={6}
        textAlign={step == "success" ? "center" : "left"}
      >
        {step == "number"
          ? "Don't worry! It occurs. Please enter the phone number linked with your account."
          : step == "otp"
          ? "Enter the verification code we just sent to you & the desired password."
          : null}
      </Typography>

      <Stack gap="20px">
        {step == "number" ? (
          <TextField
            size="small"
            id="outlined-basic"
            variant="outlined"
            value={mobileNumber}
            onChange={(e) => {
              if (Helper.validPhone(e.target.value)) {
                setMobileNumber(e.target.value);
              }
            }}
            placeholder="Enter your phone number"
            InputProps={{ className: styles.input }}
          />
        ) : step == "otp" ? (
          <>
            <OTPInput
              OTPLength={6}
              onChange={(e) => {
                setOtp(e);
              }}
              otpType="number"
              value={otp}
              inputStyles={{ marginRight: 0, width: "45px", height: "45px" }}
              style={{ justifyContent: "space-between" }}
            />
            <TextField
              size="small"
              id="outlined-basic"
              variant="outlined"
              placeholder="New Password"
              InputProps={{ className: styles.input }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </>
        ) : null}

        <Button
          onClick={() => {
            if (step == "number") {
              resend();
            }
            if (step == "otp") {
              submitHandler();
            }
          }}
          sx={{
            borderRadius: "6px",
            height: "50px",
            fontSize: 14,
            padding: "0px 60px",
            boxShadow: "none",
            backgroundColor: "#1F5972",
            margin: "0px auto",
            textTransform: "none",
            width: "100%",
          }}
          variant="contained"
        >
          {step == "number" ? "Enter Code" : step == "otp" ? "Submit" : null}
        </Button>
      </Stack>

      {(step == "number" || step == "otp") && (
        <Stack sx={{ margin: "0 auto", marginTop: "auto" }}>
          <Typography
            textAlign={"center"}
            display={"inline"}
            fontSize={14}
            color="#1F5972"
          >
            {step == "number"
              ? "Remember password?"
              : step == "otp"
              ? "Didn't receive code?"
              : null}{" "}
            <Typography
              sx={{ cursor: "pointer", marginTop: "15px" }}
              onClick={() => navigate("/login")}
              display={"inline"}
              fontSize={14}
              color="#35C2C1"
            >
              {step == "number" ? "Login Now" : step == "otp" ? "Resend" : null}
            </Typography>
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
