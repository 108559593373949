import React, { useContext ,useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { BiHomeAlt } from "react-icons/bi";
import { BiBook } from "react-icons/bi";
import { BiUserPin } from "react-icons/bi";
import logo from "../../Assets/logo.png";
import { IoLogOutOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Helper from "../../utils/helper";
import { AuthContext } from "../../Context/UserContext";
import "./MobileNave.css";

function NavMobile() {
  const routes = [
    {
      title: "Home",
      href: "/home",
      Icon: BiHomeAlt,
    },
    {
      title: "Courses",
      href: "/courses",
      Icon: BiBook,
    },
    {
      title: "MaatSaab",
      href: "/maatsaab",
      Icon: BiUserPin,
    },
  ];

  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClick = () => {
    setOpen(false);
  };

  const logout = () => {
    // Implement your logout logic here
    // For example:
    // Clear user data from localStorage and set user state to null
    // Redirect to the login page
    // Show a success toast message
    toast.success("Logged out successfully");
    localStorage.removeItem("users");
    navigate("/login");
    setOpen(false);

  };
  const { user, setUser, loading, setLoading } = useContext(AuthContext);

  return (
    <div ref={ref} className="nav-mobile">
      <div className="logo-container">
        <img
          src={logo}
          onClick={() => {
            navigate("/home");
          }}
        />
      </div>
      <div className="hamburger-container">
        <Hamburger toggled={isOpen} size={20} toggle={handleToggle} />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="menu"
          >
            <ul>
              {routes.map((route, idx) => {
                const { Icon } = route;

                return (
                  <motion.li
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20,
                      delay: 0.1 + idx / 10,
                    }}
                    key={route.title}
                    className="menu-item"
                  >
                    <Link
                      to={route.href}
                      className="menu-link"
                      onClick={() => handleClick()}
                    >
                      <Icon className="menu-icon" />
                      <span className="menu-title">{route.title}</span>
                    </Link>
                  </motion.li>
                );
              })}
              {/* Logout button */}
             { user && Helper.getLocalStorageItem("users") && 

              <motion.li
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: 0.1 + routes.length / 10,
                }}
                className="menu-item"
              >
                <a className="menu-link" onClick={logout}>
                  <IoLogOutOutline className="menu-icon" />
                  <span className="menu-title">LogOut</span>
                </a>
              </motion.li> }
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default NavMobile;
