import { Stack, Typography, Avatar } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import ReactStars from "react-rating-stars-component";

const TestimonialsSection = ({ testimonials }) => {
  return (
    <Stack marginTop={12} marginBottom={12}>
      <Typography
        marginBottom={12}
        textAlign={"center"}
        fontSize={34}
        fontWeight={600}
        color="#575757"
      >
        Testimonials
      </Typography>

      <div style={{ width: "100%", maxWidth: 600, margin: "0 auto" }}>
        <Stack
          sx={{
            backgroundColor: "#F3F4FF",
            borderRadius: 10,
            padding: "30px",
          }}
        >
          <Carousel
            autoPlay={true}
            animation="slide"
            duration={600}
            height={300}
          >
            {testimonials.map((candidate, i) => {
              return (
                <div
                  key={i}
                  style={{
                    backgroundColor: "#F3F4FF",
                    borderRadius: 10,
                    padding: "20px",
                  }}
                >
                  <Stack
                    flexDirection={"column"}
                    alignItems={"center"}
                    marginBottom={6}
                  >
                    <Avatar alt="Remy Sharp" src={candidate?.image_url} />
                    <Stack marginTop={2}>
                      <Typography fontSize={14} color="#575757">
                        {candidate?.name}
                      </Typography>
                      <Typography fontSize={10} color="#575757">
                        {candidate?.rank}
                      </Typography>
                    </Stack>
                  </Stack>
                  <ReactStars
                    count={5}
                    value={candidate?.rating}
                    size={25}
                    edit={false}
                  />
                  <Typography fontSize={16} color="#575757" fontWeight={400}>
                    {candidate?.review}
                  </Typography>
                </div>
              );
            })}
          </Carousel>
        </Stack>
      </div>
    </Stack>
  );
};

export default TestimonialsSection;
