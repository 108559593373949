import React, { useState } from "react";
import Modal from "react-modal";

import PDFViewer from "pdf-viewer-reactjs";
import VideoPlayer from "./VideoPlayer";
import { CloseOutlined } from "@mui/icons-material";

Modal.setAppElement("#root"); // Set the app root element for accessibility

function VideoAndPDFModal({ isOpen, onClose, content }) {
  const customStyles = {
    content: {
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "center", // Center content horizontally
      // justifyContent: "center", // Center content vertically
      width: "100vw", // Adjust the width as needed
      height: "100vh", // Adjust the width as needed
      // maxWidth: "800px", // Optional: Set a maximum width
      // margin: "auto", // Center the modal
      padding: 0,
      overflow: "none",
      inset: 0,
    },
    overlay: {
      zIndex: 9999,
    },
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Video and PDF Viewer Modal"
      style={customStyles}
    >
      {/* Close button */}
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <button
          onClick={onClose}
          style={{
            textDecoration: "underline",
            border: "none",
            position: "absolute",
            top: "0px",
            right: "15px",
            backgroundColor: "transparent",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          <CloseOutlined />
        </button>

        {/* Conditional rendering based on the content type */}
        <div
          style={{
            height: "100%",
            width: "100%",
            overflow: "scroll",
          }}
        >
          {content.type === "pdf" && (
            <PDFViewer
              document={{
                url: content.src,
              }}
            />
          )}
          {content.type === "video" && (
            <VideoPlayer src={content.src} width="100%" height="100%" />
          )}
          {content.type === "image" && (
            <div
              style={{
                width: "90%",
                margin: "0 auto",
                height: "100%",
              }}
            >
              <img
                alt="User Answer"
                style={{ objectFit: "contain" }}
                src={content.src}
                height="100%"
                width="100%"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default VideoAndPDFModal;
