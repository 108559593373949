import React from "react";

const AboutUs = () => {
    return (
        <div>
            <header>
                <h1>Welcome to our Adaptive TestSeries App!</h1>
            </header>
            <main>
                <section>
                    <h2>About Us</h2>
                    <p>Our app provides adaptive test series tailored to each student's needs. We understand that every student has unique strengths and weaknesses, which is why our platform generates tests focusing on areas where students need improvement.</p>
                </section>
                <section>
                    <h2>Features</h2>
                    <ul>
                        <li>Personalized Test Generation: Our app generates tests for students based on their weak areas in different subjects.</li>
                        <li>Adaptive Learning: Students get tailored test series that adapt to their progress and learning pace.</li>
                        <li>Instant Doubt Solving: We offer a unique feature named "Maatsahab" for instant doubt resolution, ensuring students have the support they need when studying.</li>
                    </ul>
                </section>
            </main>
            <footer>
                <p>Contact us for more information!</p>
            </footer>
        </div>
    );
}

export default AboutUs;