// ContactDetails.jsx
import React from 'react';
import './ContactDetails.css';

const ContactDetails = () => {
  return (
    <div className="container">
      <div className="contact-details">
        <h2>Contact Details</h2>
        <p>
          118, first floor, Radha palace, Rajpur road,<br />
          Dehradun, Uttarakhand, Pin- 248001<br />
          Pincode - 248001
        </p>
        <p>
          Delhi - 23-B,3rd Floor,Pusa Road,Metro Pillor No. 114 Above Suri Lab,Old Rajender Nagar,<br />
          New Delhi<br />
          Pincode - 110060
        </p>
        <p>
          Phone<br />
          +91 7037472279<br />
          +91 8445868955
        </p>
        <p>
          Whatsapp<br />
          +91 7037472279
        </p>
        <p>
          Mail<br />
          contact@iasskool.in
        </p>
        MACHINEMODE Y22 DOT PRIVATE LIMITED | Copyright 2023 | All Rights
        Reserved
      </div>
    
      <div className="enquire-form">
        <h2>Enquire Now</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <input type="text" id="name" placeholder="Full Name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Your email</label>
            <input type="email" id="email" placeholder="email address" />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Contact Number</label>
            <input type="tel" id="phone" placeholder="+91" />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input type="text" id="subject" placeholder="The Subject" />
          </div>
          <div className="form-group">
            <label htmlFor="message">Your message</label>
            <textarea id="message" placeholder="Leave a comment..." />
          </div>
          <button type="submit">Send message</button>
        </form>
      </div>
    </div>
    
  );
};

export default ContactDetails;