import React, { useContext, useEffect, useState } from "react";
import Helper from "../../utils/helper";
import { useLocation } from "react-router-dom";
import Modal from "react-modal";

import { FaRegFilePdf, FaCamera, FaCheck } from "react-icons/fa";
import { IoCameraOutline } from "react-icons/io5";
import { FaRetweet } from "react-icons/fa6";
import {
  DeleteOutlined,
  PublishOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Client from "../../api/client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GoInfo } from "react-icons/go";
import axios from "axios";
import { Config } from "../../api/config";
import { toast } from "react-toastify";
import { Camera, Delete } from "@mui/icons-material";
import VideoAndPDFModal from "../../Components/Modal";
import { AuthContext } from "../../Context/UserContext";
import { reevaluate } from "../../services/authService";
import { getUserTestData } from "../../services/courseService";

const SubjectiveTest = () => {
  const location = useLocation();
  const { setLoading, user } = useContext(AuthContext);
  const handleCaptureImage = () => {};
  const handleDeleteImage = () => {};
  const handleSaveQuestion = () => {};
  const [viewCopy, setViewCopy] = useState(false);
  const [courseDetails, setCourseDetails] = useState();
  const [answerArray, setAnswerArray] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [modalImage, setModalImage] = useState();
  const [modalContent, setModalContent] = useState({
    type: "",
    src: "",
  });
  Modal.setAppElement("#root"); // Set the app root element for accessibility
  const customStyles = {
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center", // Center content horizontally
      justifyContent: "center", // Center content vertically
      width: "90%", // Adjust the width as needed

      //   maxWidth: "500px", // Optional: Set a maximum width
      margin: "auto", // Center the modal
      padding: "15px",
    },
    overlay: {
      zIndex: 9999,
    },
  };
  const submitHandler = async (index, reevaulate = false) => {
    let obj = answerArray[index];

    if (!reevaulate && !obj.imagePath) {
      toast.error("Invalid activity");
    }
    const formData = new FormData();
    if (!reevaulate) {
      formData.append("files", obj.imagePath);
    }
    formData.append("testId", courseDetails?.id);
    formData.append("questionId", obj?.id);
    try {
      setLoading(true);
      const res = await axios.post(
        Config.API_URL +
          `/usersubjectiveanswersubmit?testId=${courseDetails?.id}&questionId=${obj?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              Helper.getLocalStorageItem("users")?.token
            }`,
            "Content-Type": "multipart/form-data", // You may need to adjust this content type based on your API's requirements
            // Add other headers as needed
          },
        }
      );
      if (res.status == 200) {
        setLoading(false);
        if (!reevaulate) {
          toast.success("Your answer was submitted successfully");
        }
        let arr = answerArray;
        arr[index].disable = true;
        setAnswerArray(arr);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };
  const re_evaluate = async (testId, questionId) => {
    try {
      const res = await reevaluate({
        questionId,
        testId,
        userId: user?.user?.id,
      });
      if (res.status == 200) {
        toast.success("Re-evaluation request successful");
      }
    } catch (error) {
      toast.error("Internal Server Error");
    }
  };
  const getTestData = async (id) => {
    try {
      const res = await getUserTestData(id);
      if (res.status == 200) {
        setCourseDetails(res.data);
        setAnswerArray(res.data?.questions);
      }
    } catch (error) {
      toast.error("Internal Server Error");
      console.log(error);
    }
  };
  useEffect(() => {
    getTestData(location.state?.id);
  }, []);
  const renderItem = ({ item, index }) => (
    <div>
      <div
        style={{ ...styles.questionContainer, display: "flex", gap: "15px" }}
      >
        <p
          style={{
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: "500",
            width: "3%",
            color: "#1F5972",
          }}
        >
          {index + 1}
        </p>
        <div style={styles.questionText}>
          <p dangerouslySetInnerHTML={{ __html: item?.question_desc }}></p>
          {(item?.score?.status == "REVIEWED" ||
            item?.score?.status == "REOPEN") &&
          item?.score?.reviewerupdatedfile ? (
            <div className="explanation">
              <Accordion elevation={0}>
                <AccordionSummary
                  sx={{ color: "#c1b0ee", padding: "5px" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <span
                    style={{
                      paddingTop: "2px",
                      marginRight: "6px",
                      fontSize: "18px",
                    }}
                  >
                    <GoInfo />
                  </span>{" "}
                  Explanation
                </AccordionSummary>
                <AccordionDetails
                  sx={{ color: "#1f5972", fontSize: "14px" }}
                  dangerouslySetInnerHTML={{
                    __html: item?.questionmodelanswer,
                  }}
                ></AccordionDetails>
              </Accordion>
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            width: "35%",

            // marginTop: "7px",
            // marginLeft: "12px",
            justifyContent: "flex-end",
            gap: "15px",
          }}
        >
          {!(
            (item?.score?.status == "REVIEWED" ||
              item?.score?.status == "REOPEN" ||
              item?.score?.status == "OPEN") &&
            item?.score?.reviewerupdatedfile
          ) ? (
            !item?.imagePath ? (
              <label
                style={{
                  borderRadius: "12px",
                  border: "2px solid #0e5794",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "4px 11px",
                  color: "#0e5794",
                  cursor: "pointer",
                  maxHeight: "38px",
                  textAlign: "center",
                }}
                htmlFor={`image${index}`}
              >
                <IoCameraOutline
                  size={"20px"}
                  style={{
                    position: "relative",
                    top: "4px",
                  }}
                />
              </label>
            ) : null
          ) : item?.score?.status == "OPEN" ? (
            <FaCheck
              size={"20px"}
              color="#1ca61c"
              // style={{
              //   position: "relative",
              //   top: "4px",
              // }}
            />
          ) : null}

          <input
            type="file"
            id={`image${index}`}
            style={{ display: "none" }}
            accept=".jpg, .png, .jpeg"
            disabled={answerArray[index]?.disable}
            onChange={(e) => {
              setAnswerArray((prevAnswerArray) => {
                const newArr = [...prevAnswerArray]; // Create a copy of the previous array
                newArr[index].imagePath = e.target.files[0];
                return newArr; // Return the updated array
              });
            }}
          />

          {(item?.score?.status == "REVIEWED" ||
            item?.score?.status == "REOPEN") &&
          item?.score?.reviewerupdatedfile ? (
            <>
              <div
                style={{
                  borderRadius: "12px",
                  border: "2px solid #c1b0ee",
                  maxHeight: "38px",
                  padding: "4px 11px",
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#c1b0ee",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => {
                  re_evaluate(courseDetails?.id, item?.id);
                }}
              >
                <FaRetweet
                  size={"20px"}
                  style={{
                    position: "relative",
                    top: "4px",
                  }}
                />{" "}
                Re-evaluate
              </div>
              <div
                style={{
                  borderRadius: "12px",
                  border: "2px solid #c1b0ee",
                  cursor: "pointer",
                  maxHeight: "38px",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "4px 11px",
                  color: "#c1b0ee",
                  textAlign: "center",
                }}
                onClick={() => {
                  const objectURL =
                    item?.score?.reviewerupdatedfile?.[0]?.useranswerInfo;
                  setModalActive(true);
                  setModalContent({ type: "pdf", src: objectURL });
                }}
              >
                <FaRegFilePdf
                  size={"20px"}
                  style={{
                    position: "relative",
                    top: "4px",
                  }}
                />{" "}
                View Evaluated Copy
              </div>
            </>
          ) : null}
          {item?.imagePath ? (
            <div
              style={{
                borderRadius: "12px",
                border: "2px solid #c4121b",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 500,
                maxHeight: "38px",
                padding: "2px 11px",
                color: "#c4121b",
                textAlign: "center",
              }}
              onClick={() => {
                setAnswerArray((prevAnswerArray) => {
                  const newArr = [...prevAnswerArray]; // Create a copy of the previous array
                  newArr[index].imagePath = null;
                  return newArr; // Return the updated array
                });
              }}
            >
              <DeleteOutlined
                size={"20px"}
                sx={{
                  position: "relative",
                  top: "5px",
                }}
              />
            </div>
          ) : null}

          {!(
            (item?.score?.status == "REVIEWED" ||
              item?.score?.status == "REOPEN") &&
            item?.score?.reviewerupdatedfile
          ) && item?.imagePath ? (
            <>
              <div
                style={{
                  borderRadius: "12px",
                  border: "2px solid #0e5794",
                  cursor: "pointer",
                  padding: "4px 11px",
                  maxHeight: "38px",
                  color: "#0e5794",
                  textAlign: "center",
                }}
                onClick={() => {
                  const objectURL = URL.createObjectURL(
                    answerArray[index]?.imagePath
                  );
                  setModalActive(true);
                  setModalContent({ type: "image", src: objectURL });
                }}
              >
                <VisibilityOutlined
                  size={"20px"}
                  sx={{
                    position: "relative",
                    top: "4px",
                  }}
                />
              </div>
              <div
                style={{
                  borderRadius: "12px",
                  cursor: "pointer",
                  backgroundColor: "#0e5794",
                  padding: "12px",
                  fontWeight: 500,
                  fontSize: "13px",
                  maxHeight: "38px",
                  color: "#fff",
                  textAlign: "center",
                }}
                onClick={() => {
                  submitHandler(index);
                }}
              >
                Submit Answer
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <div style={styles.container}>
      <div style={styles.infoContainer}>
        <p style={styles.infoText}>{`Test Name: ${courseDetails?.TestName}`}</p>
        <p style={styles.infoText}>{`${courseDetails?.testdescription}`}</p>
        <p style={styles.infoText}>{`Marks:${courseDetails?.questions.reduce(
          (accumulator, item) => {
            if (item && item.score && item.score?.mark != undefined) {
              return accumulator + parseInt(item.score.mark);
            } else {
              return accumulator;
            }
          },
          0
        )}/${
          courseDetails?.Marks ||
          (courseDetails?.questions && courseDetails?.questions.length * 10)
        } `}</p>
        {/* <p style={styles.infoText}>{`Evaluator Name: ${evaluatorName}`}</p> */}
      </div>
      <div style={styles.divider}></div>
      <ul style={{ listStyle: "none" }}>
        {courseDetails?.questions?.map((item, index) => (
          <li key={index}>{renderItem({ item, index })}</li>
        ))}
      </ul>
      <Modal
        isOpen={modalActive}
        onRequestClose={() => {
          setModalActive(false);
        }}
        contentLabel="Image Modal"
        style={customStyles}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <img
            alt="User Answer"
            style={{ objectFit: "contain" }}
            src={modalImage}
            height="100%"
            width="100%"
          />
        </div>
      </Modal>

      <VideoAndPDFModal
        isOpen={modalActive}
        onClose={() => {
          setModalActive(false);
        }}
        content={modalContent}
      />
    </div>
  );
};

const styles = {
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: "20px",
    paddingTop: "40px",
    fontFamily: "Poppins", // You can use a value similar to StatusBar.currentHeight
  },
  headingText: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#1F5972",
  },
  infoContainer: {
    marginBottom: "10px",
  },
  infoText: {
    fontSize: "16px",
    marginBottom: "10px",
    fontFamily: "Poppins",
    fontWeight: "700",
    color: "#1F5972",
  },
  divider: {
    height: "1px",
    backgroundColor: "#1F59728f",
    marginBottom: "20px",
  },
  questionContainer: {
    margin: "10px",
  },
  questionText: {
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: "500",
    width: "55%",
    color: "#1F5972",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  imageContainer: {
    marginRight: "10px",
  },
  thumbnailImage: {
    width: "50px",
    height: "50px",
    marginBottom: "10px",
    borderRadius: "5px",
  },
  icon: {
    width: "30px",
    height: "30px",
    color: "#1F5972",
  },
};

export default SubjectiveTest;
