import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { toast } from "react-toastify";
import Helper from "../../utils/helper";
import { _signIn } from "../../services/authService";
import { AuthContext } from "../../Context/UserContext";
export default function Login() {
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const { user, setUser, setLoading } = useContext(AuthContext);
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const loginHandler = async () => {
    if (!mobileNumber || !password) {
      toast.error("Please fill in the required fields");
      return;
    }
    if (!Helper.isValidMobileNumber(mobileNumber)) {
      toast.error("Please try with a valid phone number");
      return;
    }
    if (password.length < 6) {
      toast.error("Password must have atleast 6 characters");
      return;
    }
    try {
      setLoading(true);
      let response = await _signIn(mobileNumber, password);
      if (response.status == 200) {
        Helper.setLocalStorageItem("users", response.data);
        setLoading(false);
        toast.success("Login Successful");
        let url = Helper.getLocalStorageItem("redirectUrl");
        setTimeout(() => {
          if (url) {
            localStorage.removeItem("redirectUrl");
            navigate(url);
          } else {
            window.location.reload();
          }
        }, 1000);
        return;
      }
    } catch (error) {
      console.log("error in login ", error);
      // console.log("error",error)
      setLoading(false);
      toast.error(error.data);
    }
  };
  return (
    <Stack
      sx={{
        boxShadow:
          "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
        padding: "20px",
        borderRadius: "15px",
        maxWidth: "460px",
        width: "100%",
        margin: "30px auto",
        marginTop: 14,
      }}
    >
      <Typography
        fontSize={26}
        color="#1F5972"
        fontWeight={600}
        marginBottom={6}
        textAlign={"left"}
      >
        Welcome back! Glad to see you, Again!
      </Typography>

      <Stack gap="20px">
        <TextField
          size="small"
          id="outlined-basic"
          variant="outlined"
          maxLength={13}
          type="tel"
          // autoFocus={true}
          value={mobileNumber}
          placeholder="Enter your phone number"
          InputProps={{ className: styles.input }}
          onChange={(e) => {
            if (Helper.validPhone(e.target.value)) {
              setMobileNumber(e.target.value);
            }
          }}
        />
        <TextField
          size="small"
          id="outlined-basic"
          variant="outlined"
          placeholder="Enter your password"
          type="password"
          InputProps={{ className: styles.input }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Typography
          onClick={() => navigate("/reset")}
          color="#6A707C"
          fontSize={14}
          textAlign={"right"}
          sx={{ cursor: "pointer" }}
        >
          Forgot Password?
        </Typography>
        <Button
          sx={{
            borderRadius: "6px",
            height: "50px",
            fontSize: 14,
            padding: "0px 60px",
            boxShadow: "none",
            backgroundColor: "#1F5972",
            margin: "0px auto",
            textTransform: "none",
            width: "100%",
          }}
          onClick={loginHandler}
          variant="contained"
        >
          Login
        </Button>
      </Stack>

      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={"12px"}
        margin="20px 0"
      >
        <Divider sx={{ flex: 1 }} />
      </Stack>

      <Stack sx={{ margin: "0 auto" }}>
        <Typography
          textAlign={"center"}
          display={"inline"}
          fontSize={14}
          color="#1F5972"
        >
          Don’t have an account?{" "}
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/signup")}
            display={"inline"}
            fontSize={14}
            color="#35C2C1"
          >
            Register Now
          </Typography>
        </Typography>
      </Stack>
    </Stack>
  );
}
