import React from "react";
import { Blocks } from "react-loader-spinner";

const Loader = () => {
  return (
    <div
      style={{
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        position: "fixed",
        zIndex: 1500,
        backdropFilter: "blur(1px)",
        backgroundColor: "rgb(141 129 187 / 11%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Blocks
        height="80"
        width="80"
        color="#1f5972"
        ariaLabel="blocks-loading"
        wrapperStyle={{
          width: "10.1rem",
          margin: "0 auto",
        }}
        wrapperClass="blocks-wrapper"
        visible={true}
      />
    </div>
  );
};

export default Loader;
