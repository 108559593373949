import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams ,useLocation} from "react-router-dom";
import { checkPaymentStatus } from "../../services/paymentService";

const Checkout = () => {
  const searchParams = window.location.href
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentStatus, setpaymentStatus] = useState("wait");
  console.log(location.state)
  const { paymentData,orderData } = location.state;
  const checkStatus = async (tid) => {
    try {
      
      console.log("paymentData",paymentData)
      console.log("orderData",orderData)
      const res = await checkPaymentStatus(tid);
      console.log("res in paymentstaus",res)
      let courseId = res.data.courseId
        if (res?.data?.paymentdata.status == "captured") {
          setpaymentStatus(true);
          setTimeout(() => {
            navigate(`/course/details/${courseId}`);
          }, 3000);
        } else {
          setpaymentStatus(false);
        }
    } catch (error) {
      console.log(error);
      setpaymentStatus(false);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  };
  useEffect(() => {

    // if (searchParams.split('tid=') && searchParams.split('tid=')[1]) {
      // const TID = searchParams.split('tid=')[1]
       checkStatus(paymentData.razorpay_payment_id);
    // }
  }, []);
  return (
    <Box>
      <Typography
        fontSize={28}
        color={paymentStatus == "wait" ? "#2c2c2c" :
          paymentStatus == true ? "#3ba200" : paymentStatus == false && "#e00606"}
        fontWeight={800}
        letterSpacing={0.02}
        textAlign="center"
        marginTop={20}
      >
        {paymentStatus == "wait" ? "Waiting for payment confirmation" :
          paymentStatus == true ? "Payment Successful" : paymentStatus == false && "Payment Unsuccessful"}
      </Typography>
    </Box>
  );
};

export default Checkout;
