import { ArrowCircleLeft, North } from "@mui/icons-material";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { PiArrowCircleLeft, PiArrowCircleUpBold } from "react-icons/pi";
import { AuthContext } from "../../Context/UserContext";
import { Config } from "../../api/config";
import Helper from "../../utils/helper";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Stack, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import heroImage from "../../Assets/Designer.png";
import feature1 from "../../Assets/notes.gif";
import { FaWhatsapp } from "react-icons/fa";
import styles from "./maatsaab.module.css";
import { whatsappApi } from "../../services/authService";
import { checkPaymentStatus } from "../../services/paymentService";
import useRazorpay from "react-razorpay";
import Loader from "../../Components/Loader";




const MaatSaab = () => {
  const [Razorpay] = useRazorpay();
  const location = useLocation();
  const navigate = useNavigate();
  const planSection = useRef(null);
  const { user, setUser, _getUserSubscription, loading,setLoading } =
    useContext(AuthContext);
  
  const [userInput, setUserInput] = useState("");
 
  const [chatHistory, setChatHistory] = useState([]);
  const [SubscriptionsPlan, setSubscriptionsPlan] = useState({});
  const [limitExceeded, setLimitExceeded] = useState(false);
  const [Subscription, setSubscription] = useState({
    price: 0,
    planId: null,
    subscriptionId: null,
  });

  const [paymentStatus, setPaymentStatus] = useState({
    view: false,
    success: "wait",
  });

  const checkStatus = async (tid) => {
    try {
      setLoading(true)
      
      const res = await checkPaymentStatus(tid);
      console.log("res in paymentstaus",res.data,setLoading)
      setLoading(true)
     
        if (res?.data?.paymentdata.status == "captured") {
          setLoading(true)
          setPaymentStatus(true);
         
        
         
          setTimeout(() => {
             console.log("navigation")
            navigate("/home");
            // navigate("/maatsaab")
            toast.success("Our bots are working for you ,Please go again on maatshab ");
            setLoading(false)
          }, 3000);
        } else {
          setPaymentStatus(false);
        }
    } catch (error) {
      console.log(error);
      setPaymentStatus(false);
      setTimeout(() => {
        navigate("/maatsaab");
      }, 3000);
    }
  };

  const handleNavigationStateChange = async () => {
    if (location?.href?.includes("#tid")) {
      setPaymentStatus({ view: true, success: "wait" });
      try {
        const orderId = extractOrderIdFromUrl(location?.href);
        let token = user?.token || Helper.getLocalStorageItem("users")?.token;

        const res = await axios.get(
          Config.API_URL_Pay + `/get-payment-status?transactionId=${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status == 200 && res?.data?.data?.success) {
          toast.success("Payment successful");

          const resp = await whatsappApi(
            user?.user?.mobileNumber,
            Subscription.planId
          );

          setPaymentStatus({
            view: true,
            success: res.data?.success ? true : false,
          });
          navigate("/");
        } else if (!res?.data?.data?.success) {
          toast.error("Payment failed");
          navigate("/");
        }
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    }
  };

  const extractOrderIdFromUrl = (url) => url.split("tid=")[1];

  const _GetServiceSubscription = async () => {
    try {
      let token = user?.token || Helper.getLocalStorageItem("users")?.token;
      const res = await axios.get(
        Config.API_URL_Pay + "/subscriptionsPlan?SID=MAATSAAB",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status == 200) {
        // console.log(res?.da);
        setSubscriptionsPlan(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const _InitiatePayment = async () => {
    console.log("first",user,Helper.getLocalStorageItem("users"));
    if (!user?.token || !Helper.getLocalStorageItem("users")) {
      
      Helper.setLocalStorageItem("redirectUrl", "/maatsaab");
      toast.warn("Redirecting to login");
      setTimeout(() => navigate("login"), 2000);
      return;
    }
    if (!Subscription?.subscriptionId || !Subscription?.planId) {
      return toast.error("Invalid plan selection.");
    }
    const data = {
      subscriptionId: Subscription.subscriptionId,
      planId: Subscription.planId,
      source: "MAATSAAB",
      redirectUrl: "https://aikoach.com/maatsaab",
    };
    try {
      let token = user?.token || Helper.getLocalStorageItem("users")?.token;
     
      const res = await axios.post(
        Config.API_URL_Pay + "/InitiatePayment",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("res",res)
      if (res.status == 200) {
        setLoading(true)
        console.log("Res",res.data)
        let key = '"' + Config.razorapikey + '"';
        console.log("key",key)
        const options = {
          key:  "rzp_test_rtwC9qejw7UNcz", // Replace with your actual Razorpay key
          amount: res.data.amount,
          currency: "INR", // Example currency
          name: "MachineMode",
          description: "Payment for Your Product",
          order_id: res.data.id,
          handler: function (response,err) {
            setLoading(true)
            // Callback function to handle successful payment
            console.log("Payment successful", response);
            if(response){
              setLoading(true)
                 checkStatus(response.razorpay_payment_id)
            }
            // console.log("Payment successful", err);
            
          },
          prefill: {
            name: Helper.getLocalStorageItem("users")?.user?.firstName,
            email: Helper.getLocalStorageItem("users")?.user?.email_Id,
            contact:Helper.getLocalStorageItem("users")?.user?.mobileNumber,
          },
          theme: {
            color: "#F37254", // Example color
          },
        };
        console.log("options",Config.razorapikey)
        const razorpayInstance = new Razorpay(options);
        razorpayInstance.open();
        setLoading(false);
        // window.open(res.data.data?.instrumentResponse.redirectInfo?.url);
      } else if (res.status == 201 && res.data.data?.isFreePlan) {
        _getUserSubscription();
        navigate("/maatsaab");
        await whatsappApi(user?.user?.mobileNumber, Subscription.planId);
      }
    } catch (error) {
      setLoading(false);
      console.log("error",error);
    }
  };

  useEffect(() => {
   
      _getUserSubscription();
    
    handleNavigationStateChange();
    _GetServiceSubscription();
  }, []);
  const askQuestion = async () => {
    let data = {
      user_question: userInput,
    };
    setUserInput("");

    try {
      setLoading(true);

      const res = await axios.post("https://botapi.aikoach.com/v1/api", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status == 429) {
        let updatedPlans = SubscriptionsPlan;
        updatedPlans = SubscriptionsPlan.filter((plan) => !plan?.isFreePlan);
        setSubscriptionsPlan(updatedPlans);
        setLimitExceeded(true);
        return;
      }
      if (res.status == 200) {
        setLoading(false);
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          { type: "bot", data: res.data?.answer },
        ]);
      } else {
        setLoading(false);
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            type: "bot",
            data: `Apologies, I couldn't get a response for your question. Please try again.`,
          },
        ]);
      }
      // FOR RESPONSE WITH EXCEEDED LIMIT
    } catch (error) {
      setLoading(false);
      setChatHistory((prevChatHistory) => [
        ...prevChatHistory,
        {
          type: "bot",
          data: `Apologies, I couldn't get a response for your question. Please try again.`,
        },
      ]);
    }
  };
  useEffect(() => {
    if (chatHistory.length % 2 != 0) {
      askQuestion();
    } else {
      setUserInput("");
    }
  }, [chatHistory]);

  return (
    <>
      {loading ? (
        <Loader />
      ):(!user?.subscribed || limitExceeded) ? (
    <>
      {loading ? <Loader /> : null}
      <section className="hero bg-base" style={{ height: "88vh" }}>
        <div className="hero-body" style={{ padding: "6rem 3rem" }}>
          <div className="">
            <div className="columns">
              <div className="column mr-6 mt-12" data-aos="fade-up">
                <p className="title has-text-white has-text-weight-bold">
                  Time to add MaatSaab to your Whatsapp <FaWhatsapp />.
                </p>
                <p className="subtitle has-text-grey-light is-size-6 mt-3">
                  You can solve your doubts on WhatsApp by messaging us at{" "}
                  <a
                    href="https://wa.me/917703940953"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "green" }}
                  >
                    MaatSaab
                  </a>
                  
                </p>
                <div className="buttons">
                  <a
                    href="https://wa.me/917703940953"
                    className="button is-info"
                  >
                    <strong>Add To WhatsApp</strong>
                  </a>

                  {/* <a href="#" className="button is-primary is-outlined">
                    <strong>
                      <i className="fa-solid fa-crown"></i> Premium
                    </strong>
                  </a> */}
                </div>
              </div>
              <div className="column" data-aos="fade-left">
                <img
                  className="image has-image-centered vert-move mt-4"
                  src={heroImage}
                  alt="hero image"
                  style={{ width: "20rem", margin: "0 auto" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="has-text-centered" data-tippy-content="Scroll Down">
          <a href="#features">
            <i className="fa-solid fa-circle-chevron-down fa-lg vert-move2 has-text-white"></i>
          </a>
        </div>
      </section>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#1a2634"
          fill-opacity="1"
          d="M0,288L24,261.3C48,235,96,181,144,154.7C192,128,240,128,288,149.3C336,171,384,213,432,202.7C480,192,528,128,576,133.3C624,139,672,213,720,213.3C768,213,816,139,864,101.3C912,64,960,64,1008,106.7C1056,149,1104,235,1152,240C1200,245,1248,171,1296,144C1344,117,1392,139,1416,149.3L1440,160L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z"
        ></path>
      </svg>

      <section id="features" className="section mt-6">
        <div className="has-text-centered">
          <h1 className="title lined">Features</h1>
          <div className="line line-center blurple"></div>
        </div>

        <div className="single-feature">
          <div className="shape-right" data-aos="fade-up-left">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#9AAAE3"
                d="M42.7,-62.9C50.9,-52.8,50.1,-34.4,51.7,-19.2C53.4,-4,57.4,8,56.6,20.8C55.8,33.7,50.1,47.4,39.9,53.8C29.6,60.1,14.8,59.1,0.4,58.5C-14,58,-28,57.9,-38,51.5C-48.1,45,-54.3,32.3,-61.3,18.1C-68.4,4,-76.4,-11.7,-71.9,-22.7C-67.4,-33.6,-50.4,-39.8,-36.3,-47.9C-22.2,-56.1,-11.1,-66.3,3.1,-70.5C17.2,-74.7,34.5,-72.9,42.7,-62.9Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>

          <div className="columns mt-6">
            <div className="column mr-6">
              <h4 className="title">
                Feature <span className="blurple">#1</span>
              </h4>
              <p className="subtitle mt-3">
                MaatSaab excels in addressing diverse queries across languages
                and subjects, making it a valuable resource for any exam
                preparation. With the ability to comprehend and solve problems,
                users can seek assistance effortlessly, making it an effective
                tool for resolving a wide range of academic challenges in any
                language.This bot excels in solving mathematical problems,
                accepting input in the form of images. you can submit pictures
                of math questions, and the bot will analyze the content to
                provide accurate solutions. This feature streamlines the process
                of getting assistance with math problems, making learning more
                accessible and interactive.
              </p>
            </div>
            <div className="column" data-aos="fade-left">
              {/* FOR VIDEO */}
              {/* <video width="100%" height="100%" controls>
                    <source src="your-feature1-video.mp4" type="video/mp4">
                    <source src="your-feature1-video.webm" type="video/webm">
                    Your browser does not support the video tag.
                </video> */}
              <img
                className="image has-image-centered"
                src={feature1}
                alt="feature1 img"
                style={{ width: "20rem" }}
              />
            </div>
          </div>
        </div>

        <div className="single-feature">
          <div className="shape-left" data-aos="fade-up-right">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#9AAAE3"
                d="M54.2,-67.2C69.4,-63.5,80.4,-46.6,84.6,-28.7C88.7,-10.8,86,8.1,76.8,21.4C67.6,34.7,51.9,42.3,38.1,50.3C24.2,58.3,12.1,66.7,-2.8,70.5C-17.7,74.3,-35.3,73.6,-45.6,64.5C-55.9,55.3,-58.8,37.7,-63.3,21.2C-67.7,4.7,-73.7,-10.6,-71.8,-25.4C-69.8,-40.2,-59.9,-54.5,-46.6,-58.9C-33.3,-63.2,-16.7,-57.6,1.4,-59.6C19.5,-61.5,39,-71,54.2,-67.2Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>

          <div className="columns mt-6">
            <div className="column mr-6">
              <h4 className="title">
                Feature <span className="blurple">#2</span>
              </h4>
              <p className="subtitle mt-3">
                Engage in interactive learning with this bot by instructing it
                to play on any topic of your choice. Not only can it answer your
                queries, but it can also dynamically generate questions,
                enhancing your learning experience across a broad spectrum of
                subjects.
              </p>
            </div>
            <div className="column" data-aos="fade-left">
              {/* FOR VIDEO */}
              {/* <video width="100%" height="100%" controls>
                    <source src="your-feature1-video.mp4" type="video/mp4">
                    <source src="your-feature1-video.webm" type="video/webm">
                    Your browser does not support the video tag.
                </video> */}
              <img
                className="image has-image-centered"
                src={heroImage}
                alt="feature1 img"
                style={{ width: "20rem" }}
              />
            </div>
          </div>
        </div>
        <div className="single-feature">
          <div className="shape-right" data-aos="fade-up-left">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#9AAAE3"
                d="M42.7,-62.9C50.9,-52.8,50.1,-34.4,51.7,-19.2C53.4,-4,57.4,8,56.6,20.8C55.8,33.7,50.1,47.4,39.9,53.8C29.6,60.1,14.8,59.1,0.4,58.5C-14,58,-28,57.9,-38,51.5C-48.1,45,-54.3,32.3,-61.3,18.1C-68.4,4,-76.4,-11.7,-71.9,-22.7C-67.4,-33.6,-50.4,-39.8,-36.3,-47.9C-22.2,-56.1,-11.1,-66.3,3.1,-70.5C17.2,-74.7,34.5,-72.9,42.7,-62.9Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>

          <div className="columns mt-6">
            <div className="column mr-6">
              <h4 className="title">
                Feature <span className="has-text-warning">#3</span>
              </h4>
              <p className="subtitle mt-3">
                This bot functions as a virtual teacher, aiding in note-taking
                for efficient topic revision. Users can prompt the bot to
                generate comprehensive notes on specific subjects, facilitating
                a personalized study guide. This feature enhances learning by
                offering tailored content, helping users consolidate and review
                information effectively for exams or self-improvement. it will
                be saved for your future reference.
              </p>
            </div>
            <div className="column" data-aos="fade-left">
              <img
                className="image has-image-centered"
                src={heroImage}
                alt="feature1 img"
                style={{ width: "20rem" }}
              />
            </div>
          </div>
        </div>
        <div className="single-feature">
          <div className="shape-left" data-aos="fade-up-right">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#9AAAE3"
                d="M54.2,-67.2C69.4,-63.5,80.4,-46.6,84.6,-28.7C88.7,-10.8,86,8.1,76.8,21.4C67.6,34.7,51.9,42.3,38.1,50.3C24.2,58.3,12.1,66.7,-2.8,70.5C-17.7,74.3,-35.3,73.6,-45.6,64.5C-55.9,55.3,-58.8,37.7,-63.3,21.2C-67.7,4.7,-73.7,-10.6,-71.8,-25.4C-69.8,-40.2,-59.9,-54.5,-46.6,-58.9C-33.3,-63.2,-16.7,-57.6,1.4,-59.6C19.5,-61.5,39,-71,54.2,-67.2Z"
                transform="translate(100 100)"
              />
            </svg>
          </div>

          <div className="columns mt-6">
            <div className="column mr-6">
              <h4 className="title">
                Feature <span className="blurple">#4</span>
              </h4>
              <p className="subtitle mt-3">
                This bot takes personalized learning to the next level by
                crafting a tailored study path for any exam. By analyzing your
                strengths and weaknesses, it intelligently generates targeted
                tests and recommends relevant educational videos. This adaptive
                approach empowers users to focus on and master areas of
                improvement, optimizing the learning experience for exam
                preparation.
              </p>
            </div>
            <div className="column" data-aos="fade-left">
              {/* FOR VIDEO */}
              {/* <video width="100%" height="100%" controls>
                    <source src="your-feature1-video.mp4" type="video/mp4">
                    <source src="your-feature1-video.webm" type="video/webm">
                    Your browser does not support the video tag.
                </video> */}
              <img
                className="image has-image-centered"
                src={heroImage}
                alt="feature1 img"
                style={{ width: "20rem" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section id="stats" className="section mt-6">
        <div className="has-text-centered">
          <h1 className="title lined">Stats</h1>
          <div className="line line-center blurple"></div>
        </div>

        <div className="columns mt-6">
          <div className="column has-text-centered">
            <p className="title has-text-weight-bold lined">
              {parseInt((Math.random() * (15000000 - 5000) + 5000) / 1000)}k
            </p>
            <span className="subtitle has-text-weight-bold blurple">
              <i className="fa-solid fa-server"></i> Questions Asked
            </span>
          </div>
          {/* 
          <div className="column has-text-centered">
            <p className="title has-text-weight-bold lined">{Math.random() * (150000 - 5000) + 5000}</p>
            <span className="subtitle has-text-weight-bold blurple">
              <i className="fa-solid fa-terminal"></i> Commands
            </span>
          </div> */}

          <div className="column has-text-centered">
            <p className="title has-text-weight-bold lined">30K</p>
            <span className="subtitle has-text-weight-bold blurple">
              <i className="fa-solid fa-users"></i> Users
            </span>
          </div>
        </div>
      </section>

      {/* <section class="section mt-6">
        <div class="columns">
          <div class="column has-text-left">
            <p class="title has-text-weight-bold">
              Ready to try <span class="blurple">[your bot]</span>?
            </p>
            <p class="subtitle mt-3 has-text-gray">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <a href="#" class="button is-blurple is-medium">
              <strong>
                <i class="fa-solid fa-book"></i> Get Started
              </strong>
            </a>
          </div>

          <div class="column"></div>
        </div>
      </section> */}
      <Stack sx={{ height: "87vh", width: "100vw" }}>
        <div className="has-text-centered" style={{ marginBottom: "10px" }}>
          <h1 className="title lined" style={{ marginTop: "10px" }}>
            Upgrade your plan
          </h1>
          <div
            className="line line-center"
            style={{ backgroundColor: "#1F5972" }}
          ></div>
        </div>
        <Typography
          fontSize={21}
          fontWeight={400}
          color="#000"
          paddingLeft={"25px"}
          paddingRight={"25px"}
          textAlign="center"
          marginBottom={"20px"}
        >
          Elevate your educational impact! Upgrade to our PRO plan for advanced
          features, broader reach, and a more vibrant learning community.
        </Typography>
        <Typography
          fontSize={20}
          fontWeight={500}
          color="#1F5972"
          textAlign="center"
          marginBottom={"20px"}
        >
          Select Your Plan
        </Typography>
        <div style={{ marginBottom: "15px" }}></div>
        <Stack
          flexDirection="row"
          // height={"250px"}
          ref={planSection}
          margin={"0 auto"}
          width={"50%"}
          gap="50px"
          justifyContent="space-evenly"
          sx={{
            "@media (max-width: 768px)": {
              flexDirection: "column", // Stack vertically on smaller screens
              width: "80%",
              gap: "10px", // Adjust the gap for better spacing
              marginBottom: "20px",
              // overflowY: "auto", // Adjust width for better responsiveness
            },
          }}
        >
        {SubscriptionsPlan?._id && SubscriptionsPlan?.plans
  ? SubscriptionsPlan?.plans
      ?.filter(plan => plan._id !== user?.planId)
      .map((plan, i) => (
        <Stack
          key={i}
          padding="8px"
          borderRadius={"15px"}
          maxWidth={"300px"}
          minWidth={"250px"}
          sx={
            Subscription.planId === plan?._id
              ? {
                  border: "3px solid #2596be",
                  cursor: "pointer",
                  backgroundColor: "#E7FAFE",
                  boxShadow: "0 0 10px 3px #1f59724d",
                  paddingLeft: "20px",
                }
              : {
                  border: "none",
                  paddingLeft: "20px",
                  cursor: "pointer",
                  backgroundColor: "#E7FAFE",
                  boxShadow: "0 0 10px 3px #1f59724d",
                }
          }
          onClick={() => {
            setSubscription({
              price: plan.price,
              planId: plan._id,
              subscriptionId: SubscriptionsPlan?._id,
            });
          }}
        >
          <Stack
            flexDirection="row"
            padding={"10px"}
            gap="8px"
            alignItems="flex-start"
          >
            <Typography fontSize={20} fontWeight={600} color="#000">
              {`${plan.name} - ₹${plan.price}/${
                plan.duration === 1 ? "month" : "year"
              }`}
            </Typography>
          </Stack>

          {SubscriptionsPlan?.features ? (
            <>
              <Typography fontSize={17} fontWeight={500} color="#000">
                What will you get
              </Typography>
              {SubscriptionsPlan?.features?.map((feat, i) => (
                <Typography
                  key={i}
                  fontSize={15}
                  fontWeight={400}
                  color="#000"
                  sx={{ listStyleType: "disc", paddingLeft: "20px" }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: "-20px",
                      top: "4px",
                      display: "inline-block",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "#000",
                      borderRadius: "50%",
                    }}
                  ></span>
                  {i + 1} {feat}
                </Typography>
              ))}
            </>
          ) : null}
        </Stack>
      ))
  : null}

        </Stack>
      </Stack>
      {Subscription.planId ? (
        <div className={styles.cartCheckout}>
          <div>
            <Typography fontSize={15} fontWeight={700} color="#6f6f6f">
              Total
            </Typography>
            <Typography fontSize={18} fontWeight={700} color="#0E5794">
              &#8377; {Subscription?.price}
            </Typography>
          </div>
          <Button
            sx={{
              backgroundColor: "#1f5972",
              color: "#fff",
              borderRadius: "10px",
              fontWeight: 500,
              width: "110px",
              textAlign: "center",
              padding: "10px 15px",
              cursor: "pointer",
              border: "none",
            }}
            variant="contained"
            onClick={() => {
              _InitiatePayment();
            }}
          >
            Upgrade now
          </Button>
        </div>
      ) : null}
    </>
  ) : (
<div
  style={{
    position: "relative",
    height: "100vh",
    width: "100%",
    backgroundColor: "#d5d5d5",
    padding: "10px",
    boxSizing: "border-box",
    fontFamily: "Poppins",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <div
    style={{
      position: "relative",
      width: "100%",
      maxWidth: "800px", // Limiting maximum width for better readability on larger screens
    }}
  >
    <div
      style={{
        position: "absolute",
        top: "10px",
        right: "10px",
      }}
    >
      {user?.isFreePlan && (
        <button
          style={{
            backgroundColor: "#1f5972",
            color: "#fff",
            borderRadius: "10px",
            fontWeight: 500,
            width: "110px",
            textAlign: "center",
            padding: "10px 15px",
            cursor: "pointer",
            border: "none",
          }}
          onClick={() => {
            setLimitExceeded(true);
            if (planSection?.current) {
              return planSection.current.scrollIntoView();
            }
          }}
        >
          Update
        </button>
      )}
    </div>
    <div style={{ textAlign: "center", marginBottom: "20px" }}>
      <p
        style={{
          fontSize: "22px",
          color: "#043e50",
          fontWeight: 600,
          margin: 0,
        }}
      >
        MaatSaab
      </p>
    </div>

    <div
      className="chat-section"
      style={{
        maxHeight: "60vh", // Limiting maximum height for chat section
        overflowY: "auto",
      }}
    >
      {chatHistory.length ? (
        chatHistory.map((ele, i) => (
          <div
            className="message"
            key={i}
            style={{
              display: "flex",
              justifyContent: ele.type === "bot" ? "flex-start" : "flex-end",
              margin: "10px auto",
            }}
          >
            <p
              style={{
                padding: "10px",
                maxWidth: "70%",
                borderRadius: "12px",
                backgroundColor: ele.type === "bot" ? "#c4c1c1" : "#5d5dcb",
                color: ele.type === "bot" ? "#000" : "#fff",
                wordWrap: "break-word",
                fontSize: "12px",
                letterSpacing: "0.02em",
              }}
            >
              {ele.data}
            </p>
          </div>
        ))
      ) : (
        <p style={{ textAlign: "center" }}>No messages yet.</p>
      )}
    </div>

    <div
      className="maat-saab-input-container"
      style={{
        backgroundColor: "#fff",
        borderRadius: "12px",
        padding: "7px 15px",
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <input
        style={{
          flex: "1",
          border: "none",
          outline: "none",
          height: "40px",
        }}
        value={userInput}
        onChange={(e) => {
          setUserInput(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && userInput && userInput.length >= 3) {
            setChatHistory((prevChatHistory) => [
              ...prevChatHistory,
              { type: "user", data: userInput },
            ]);
          }
        }}
        placeholder="Ask Your Mentor"
      />
      <div
        style={{
          backgroundColor: "#043e50",
          color: "#fff",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          marginLeft: "10px",
        }}
        onClick={() => {
          if (userInput && userInput.length >= 3) {
            setChatHistory((prevChatHistory) => [
              ...prevChatHistory,
              { type: "user", data: userInput },
            ]);
          }
        }}
      >
        <North fontSize="medium" />
      </div>
    </div>
  </div>
</div>

  )};
  </>
  )};

export default MaatSaab;
