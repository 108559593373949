import { Stack, Typography } from "@mui/material";
import React, { useRef, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import coinIcon from "../Assets/goldcoin.webp";
import { Check, CleaningServices } from "@mui/icons-material";
import { AuthContext } from "../Context/UserContext";

export default function CourseCard(props) {
  const [readMoreCard, setReadMoreCard] = useState(null);
  const { user, setUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const readMoreRef = useRef(null);
  return (
    <Stack
      padding="8px"
      border="1px solid #E3E3E3"
      borderRadius={"15px"}
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        console.log("props",props)
        if (readMoreRef?.current && readMoreRef?.current?.contains(e.target)) {
          return;
        } else  {
          navigate(props.navigate, { state: props?.data });

        // }else {
        //   navigate('/login');
        }
      }}
    >
      <div style={{ position: "relative" }}>
        {props?.data?.enrolled ? (
          <div style={{ position: "absolute", bottom: 20, right: 6 }}>
            <span
              style={{
                backgroundColor: "#0e5794",
                borderRadius: "10px",
                fontSize: "12px",
                fontWeight: "600",
                color: "#fff",
                padding: "5px 10px",
                position: "relative",
                bottom: "6px",
              }}
            >
              Enrolled
            </span>
          </div>
        ) : (
          <div style={{ position: "absolute", bottom: 15, right: 10 }}>
            <span
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#0e5794",
                backgroundColor: "#fff",
                position: "relative",
                bottom: "6px",
                padding: "2px 5px",
                borderRadius: "10px",
              }}
            >
              &#8377; {props?.data?.packagePrice}
            </span>
          </div>
        )}

        {props?.data?.imagePath ? (
  <img
    style={{
      display: "block",
      height: "auto", // Change height to auto
      width: "100%",
      objectFit: "contain", // Change objectFit to contain
      marginBottom: 18,
      borderRadius: 15,
      cursor: "pointer",
    }}
    alt="Course Cover"
    src={props.data?.imagePath}
  />
) : (
  <img
    style={{
      display: "block",
      height: "auto", // Change height to auto
      width: "100%",
      objectFit: "contain", // Change objectFit to contain
      marginBottom: 18,
      borderRadius: 15,
      cursor: "pointer",
    }}
    alt="Course Cover"
    src="https://images.unsplash.com/photo-1552862750-746b8f6f7f25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGlnaHRidWxifGVufDB8fDB8fHww&w=1000&q=80"
  />
)}

      </div>

      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom="18px"
      >
        <Typography fontSize={17} fontWeight={700} variant="h2" color="#5F81B3">
          {props?.data?.packageName}
        </Typography>
        <Typography
          fontSize={12}
          fontWeight={500}
          color="#3E3E3E"
          sx={{ textDecoration: "underline" }}
        >
          {/* {props?.data?.orderinfo ? props?.data?.orderinfo.length : 0} Lessons */}
        </Typography>
      </Stack>
      {props?.data?.packageDesc?.length > 150 ? (
  <>
    <Typography fontSize={16} fontWeight={600} color="#0E5794">
      {readMoreCard === props?.data?.id
        ? props?.data?.packageDesc
        : props?.data?.packageDesc?.slice(0, 150)}
    </Typography>
    {readMoreCard === props?.data?.id ? (
      <Typography
        sx={{
          textDecoration: "underline",
        }}
        color="#3f3f3f"
        textAlign={"end"}
        fontSize={12}
        fontWeight={500}
        ref={readMoreRef}
        onClick={() => {
          setReadMoreCard(null);
        }}
      >
        Read less
      </Typography>
    ) : (
      <Typography
        fontSize={12}
        fontWeight={500}
        ref={readMoreRef}
        sx={{
          textDecoration: "underline",
        }}
        color="#3f3f3f"
        textAlign={"end"}
        onClick={() => {
          setReadMoreCard(props?.data?.id);
        }}
      >
        Read More
      </Typography>
    )}
  </>
) : (
  <Typography fontSize={16} fontWeight={600} color="#0E5794">
    {props?.data?.packageDesc}
  </Typography>
)}

    </Stack>
  );
}
