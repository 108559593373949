import React, { useState, useEffect } from "react";
import { Typography, Grid, Stack, Button, InputAdornment, TextField } from "@mui/material";
import { BiSearch } from "react-icons/bi";
import CourseCard from "../../Components/CourseCard";
import styles from "./Courses.module.css";

const ITEMS_PER_PAGE = 3;

export default function UserCourses({ userCourses }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCourses, setFilteredCourses] = useState(userCourses);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedCourses, setDisplayedCourses] = useState([]);

  useEffect(() => {
    // console.log('@@123' , userCourses)
    setFilteredCourses(userCourses);
  }, [userCourses]);

  useEffect(() => {
    const filtered = userCourses?.filter(course =>
      course?.packageName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
    setFilteredCourses(filtered);
    setCurrentPage(1); // Reset to first page when search query changes
  }, [userCourses, searchQuery]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, filteredCourses?.length);
    const newDisplayedCourses = filteredCourses?.slice(0, endIndex); // Display all filtered courses
    setDisplayedCourses(newDisplayedCourses);
  }, [currentPage, filteredCourses]);

  const handleLoadMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        marginBottom={2}
      >
        <Grid item xs={12} md={6}>
          <Typography fontSize={22} color="#002835" display={"inline"}>
            Take a Look into Your Courses
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BiSearch size={24} />
                </InputAdornment>
              ),
            }}
            sx={{ borderWidth: 0, width: "100%" }}
            placeholder="Search courses..."
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {displayedCourses?.map((course, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <CourseCard
              courseScreen={true}
              data={course}
              navigate={`/course/details/${course?.id}`}
            />
          </Grid>
        ))}
      </Grid>
      {filteredCourses?.length > displayedCourses?.length && (
        <Stack direction="row" spacing={2} justifyContent="center" marginTop={2}>
          <Button variant="contained" onClick={handleLoadMore}>Load More</Button>
        </Stack>
      )}
      {filteredCourses?.length === 0 && <Typography className={styles.noCoursesMessage}>No courses found.</Typography>}
    </div>
  );
}
