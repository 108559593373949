import React, { useContext, useEffect, useState } from "react";
import { CoursePackageContext } from "../../Context/CoursesContext";
import { Box, Button, Typography } from "@mui/material";
import CartCard from "../../Components/CartCard";
import { AuthContext } from "../../Context/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Config } from "../../api/config";
import Helper from "../../utils/helper";
import { toast } from "react-toastify";
import styles from "./Cart.module.css";
import useRazorpay from "react-razorpay";



const Cart = () => {
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const { CoursePackage } = useContext(CoursePackageContext);
  const { setLoading } = useContext(AuthContext);
  const { id } = useParams();

  const [recommendedPackages, setRecommendedPackages] = useState([
    {
      id: "d1fa15a3-6ee0-45c8-9a95-212f0abe6a7a",
      packageName: "ELPT",
      packageDesc: "Ideal for beginners to kickstart their learning journey.",
      packagePrice: 9999,
      expiry: 12, // in month,
      imagePath:
        "https://image2.slideserve.com/3674307/english-language-proficiency-test-elpt-l.jpg",
    },
    {
      id: "d1fa15a3-6ee0-45c6-9a95-212f0abe6a7a",
      packageName: "Python",
      packageDesc:
        "Ideal for Intermediate to kickstart their learning journey.",
      packagePrice: 20999,
      expiry: 12, // in month,
      imagePath:
        "https://www.etudemy.com/wp-content/uploads/2022/01/Computer-Courses-in-Perinthalmanna-python-Copy.jpg",
    },
  ]);

  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [mainItem, setMainItem] = useState({});

  const checkout = async () => {
    try {
      setLoading(true);
      const res = await axios.post(
        Config.API_URL_Pay + "/checkout",
        {
          PkgId: cartItems,
          redirectUrl: "https://aikoach.com/checkout-status",
        },
        {
          headers: {
            Authorization: `Bearer ${
              Helper.getLocalStorageItem("users")?.token
            }`,
          },
        }
      );
      setLoading(false);
      if (res.status === 200) {
        console.log("res in cart",res.data)
        const options = {
          key: Config.razorapikey, // Replace with your actual Razorpay key
          amount: res.data.amount,
          currency: "INR", // Example currency
          name: "MachineMode",
          description: "Payment for Your Product",
          order_id: res.data.id,
          handler: function (response,err) {
            // Callback function to handle successful payment
            console.log("Payment successful", response);
            // console.log("Payment successful", err);
            navigate("/checkout-status",{ state: { paymentData: response, orderData: res.data } });
          },
          prefill: {
            name: "Customer Name",
            email: "customer@example.com",
            contact: "1234567890",
          },
          theme: {
            color: "#F37254", // Example color
          },
        };
        const razorpayInstance = new Razorpay(options);
        razorpayInstance.open();
      }
        // window.open(res.data.data?.instrumentResponse.redirectInfo?.url);
      }
     catch (error) {
      setLoading(false);
      toast.error("Internal Server Error");
      console.error(error);
    }
  };

  useEffect(() => {
    const mainCartItem = CoursePackage.find((ele) => ele.id === id);
    if (mainCartItem) {
      setMainItem(mainCartItem);
      setCartItems([mainCartItem?.id]);
      setCartTotal(parseInt(mainCartItem.packagePrice));
    } else {
      navigate("/");
    }
  }, [CoursePackage, id, navigate]);

  return (
    <>
      <Box
        sx={{
          padding: "20px",
          paddingBottom: "50px",
          backgroundColor: "#efefef",
        }}
      >
        <Box>
          <Typography
            fontSize={22}
            fontWeight={700}
            color="#002835"
            display={"inline"}
            marginBottom={10}
          >
            Your Items
          </Typography>
          <div className={styles.coursesCardContainer}>
            <CartCard data={mainItem} />
          </div>
        </Box>
        <Box>
          <Typography
            fontSize={22}
            fontWeight={700}
            color="#002835"
            display={"inline"}
            marginBottom={10}
marginTop={10}
          >
            Recommended
          </Typography>
          <div className={styles.coursesCardContainer}>
            {recommendedPackages.map((ele, ind) => (
              <CartCard
                data={ele}
                key={ind}
                selected={ele?.selected ? ele?.selected : false}
                onClick={() => {
                  if (cartItems.includes(ele.id)) {
                    setCartItems(cartItems.filter((el) => el.id !== ele.id));
                    setCartTotal(cartTotal - parseInt(ele.packagePrice));
                    setRecommendedPackages(
                      recommendedPackages.map((rec) =>
                        rec.id === ele.id ? { ...rec, selected: false } : rec
                      )
                    );
                  } else {
                    setCartItems([...cartItems, ele.id]);
                    setCartTotal(cartTotal + parseInt(ele.packagePrice));
                    setRecommendedPackages(
                      recommendedPackages.map((rec) =>
                        rec.id === ele.id ? { ...rec, selected: true } : rec
                      )
                    );
                  }
                }}
              />
            ))}
          </div>
        </Box>
      </Box>
      <div className={styles.cartCheckout}>
        <div>
          <Typography fontSize={15} fontWeight={700} color="#6f6f6f">
            Total
          </Typography>
          <Typography fontSize={18} fontWeight={700} color="#0E5794">
            &#8377; {cartTotal}
          </Typography>
        </div>
        <Button
          sx={{
            backgroundColor: "#1f5972",
            color: "#fff",
            borderRadius: "10px",
            fontWeight: 500,
            width: "110px",
            textAlign: "center",
            padding: "10px 15px",
            cursor: "pointer",
            border: "none",
          }}
          variant="contained"
          onClick={checkout}
        >
          Checkout
        </Button>
      </div>
    </>
  );
};

export default Cart;
