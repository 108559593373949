import React, { useContext, useEffect } from "react";
import styles from "./Navbar.module.css";
import logo from "../Assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/UserContext";
import { Logout } from "@mui/icons-material";
import { toast } from "react-toastify";
import Helper from "../utils/helper";
import Loader from "./Loader";

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname !== "/login") {
      let url = Helper.getLocalStorageItem("redirectUrl");
      if (url) {
        localStorage.removeItem("redirectUrl");
        navigate(url);
      }
    }
  }, []);
  const { user, setUser, loading, setLoading } = useContext(AuthContext);
  return (
    <>
      {loading ? <Loader /> : null}
      <nav className={styles.nav}>
        <img
          src={logo}
          onClick={() => {
            navigate("/home");
          }}
        />
        <ul>
          <li>
            <Link
              style={{ color: location.pathname == "/home" && "#575757" }}
              to="/home"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              style={{ color: location.pathname == "/courses" && "#575757" }}
              to="/courses"
            >
              Courses
            </Link>
          </li>
          <li>
            <Link
              style={{ color: location.pathname == "/maatsaab" && "#575757" }}
              to="/maatsaab"
            >
              MaatSaab
            </Link>
          </li>
          {user && Helper.getLocalStorageItem("users") ? (
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                setLoading(true);
                setUser(null);
                localStorage.removeItem("users");

                setTimeout(() => {
                  setLoading(false);
                  Helper.setLocalStorageItem("redirectUrl", "/login");
                  window.location.reload();
                }, 1000);
                toast.success("Logged out successfully");
              }}
            >
              <Logout sx={{ color: "#828282" }} />
            </li>
          ) : null}
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
