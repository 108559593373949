import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { toast } from "react-toastify";
import Helper from "../../utils/helper";
import { _signup } from "../../services/authService";
import { AuthContext } from "../../Context/UserContext";

export default function Signup() {
  const { setLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [username, setUsername] = useState("");
  const [exam, setExam] = useState("");
  const [language, setLanguage] = useState("");
  const [password, setPassword] = useState("");

  const validPhone = (num) => {
    return num.length <= 10 && !isNaN(num);
  };
  const signUpHandler = async () => {
    if (!password || !username || !mobileNumber || !exam || !language) {
      toast.error("Please fill in the required fields");
      return;
    }

    if (!Helper.isValidMobileNumber(mobileNumber)) {
      toast.error("Please enter valid phone number");
      return;
    }
    if (mobileNumber.length < 6) {
      toast.error("Phone Number must have at least 6 digits");
      return;
    }
    if (password.length < 6) {
      toast.error("Password must have at least 6 characters");
      return;
    }
    let obj = {
      mobileNumber,
      username,
      exam,
      language,
      password,
    };
    try {
      setLoading(true);
      let response = await _signup(obj);
      if (response.status == 200) {
        setLoading(false);
        Helper.setLocalStorageItem("otpDetails", obj);
        toast.success("Sign Up Successful");
        navigate("/otp");
        return;
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.data.message);
    }
  };
  // mobileNumber, password, email_Id,role,firstName,lastName
  return (
    <Stack
      sx={{
        boxShadow:
          "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
        padding: "20px",
        borderRadius: "15px",
        maxWidth: "460px",
        width: "100%",
        margin: "30px auto",
        marginTop: 14,
      }}
    >
      <Typography
        fontSize={26}
        color="#1F5972"
        fontWeight={600}
        marginBottom={6}
        textAlign={"center"}
      >
        Welcome to AI -Koach
      </Typography>

      <Stack gap="20px">
        <TextField
          size="small"
          id="outlined-basic"
          variant="outlined"
          placeholder="Full Name"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          InputProps={{ className: styles.input }}
        />
        <TextField
          size="small"
          id="outlined-basic"
          variant="outlined"
          placeholder="Phone Number"
          value={mobileNumber}
          type="tel"
          onChange={(e) => {
            if (validPhone(e.target.value)) {
              setMobileNumber(e.target.value);
            }
          }}
          InputProps={{ className: styles.input }}
        />
        <TextField
          size="small"
          id="outlined-basic"
          variant="outlined"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          InputProps={{ className: styles.input }}
        />
        <TextField
          size="small"
          id="outlined-basic"
          variant="outlined"
          placeholder="Examination e.g. UPSC, SSC, etc."
          value={exam}
          onChange={(e) => setExam(e.target.value)}
          InputProps={{ className: styles.input }}
        />
        <TextField
          size="small"
          id="outlined-basic"
          variant="outlined"
          placeholder="Exam Medium e.g. English, Hindi, etc."
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
          InputProps={{ className: styles.input }}
        />
        <Button
          sx={{
            borderRadius: "6px",
            height: "50px",
            fontSize: 14,
            padding: "0px 60px",
            boxShadow: "none",
            backgroundColor: "#1F5972",
            margin: "0px auto",
            textTransform: "none",
            width: "100%",
          }}
          variant="contained"
          onClick={signUpHandler}
        >
          Agree and Register
        </Button>
      </Stack>

      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={"12px"}
        margin="20px"
      >
        <Divider sx={{ flex: 1 }} />
      </Stack>

      <Stack onc sx={{ margin: "0 auto" }}>
        <Typography
          textAlign={"center"}
          display={"inline"}
          fontSize={14}
          color="#1F5972"
        >
          Already have an account?{" "}
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/login")}
            display={"inline"}
            fontSize={14}
            color="#35C2C1"
          >
            Login Now
          </Typography>
        </Typography>
      </Stack>
    </Stack>
  );
}
