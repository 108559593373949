import { Button, Stack, Typography, CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styles from "./CourseDetails.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  _getCourseDetails,
  _getuserCourseDetails,
} from "../../services/courseService";
import { toast } from "react-toastify";
import VideoAndPDFModal from "../../Components/Modal";
import { CoursePackageContext } from "../../Context/CoursesContext";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../Context/UserContext";
import { FaFilePdf, FaFileVideo, FaVideo } from "react-icons/fa";

export default function CourseDetails(props) {
  const { userCourses } = useContext(CoursePackageContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [course, setCourse] = useState(location.state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enrolled, setEnrolled] = useState(false);
  const [readMoreCard, setReadMoreCard] = useState(null);
  const readMoreRef = useRef(null);
  const [modalContent, setModalContent] = useState({ type: "", src: "" });
  const [loading, setLoading] = useState(true); // Added loading state
  const { user } = useContext(AuthContext);

  const openModal = (type, src) => {
    setModalContent({ type, src });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { id } = useParams();

  useEffect(() => {
    MyCourseDetail(id);
  }, []);
  const MyCourseDetail = async (id) => {
    try {
      const res = await _getCourseDetails(id, user);

      if (res.status == 200) {
        setCourse(res.data);
        if (localStorage.getItem("users")) {
          const res1 = await _getuserCourseDetails(id);
          if (res1.status == 200) {
            console.log(
              "res in userCourseDetailgetcoursedetails if",
              res1?.data?.enrolled
            );
            if (res1.data.enrolled == true) {
              console.log(
                "res in userCourseDetailgetcoursedetails in true",
                res1?.data?.enrolled
              );
              setEnrolled(true);
              console.log("enrolled", enrolled);
            }
          }
        } else {
          setEnrolled(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // Optional: Code to run after initial render (if needed)
  // }, []);

  // useEffect(() => {
  //   MyCourseDetail(id);
  // }, []);
  // useEffect(() => {
  //   userCourseDetail(id)
  // }, []);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }
  if (!course) {
    // Handle the case when course data is not available yet
    return <div>Loading course data...</div>;
  }

  return (
    <div className={styles.courseDetailsContainer}>
      <Helmet>
        <title>{course?.packagedeatils?.packageName} - AI Koach</title>
        <meta
          name="description"
          content={course?.packagedetails?.packageDesc || ""}
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      {/* Desktop Design */}
      <div className={styles.desktopView}>
        <Stack padding="20px" flexDirection={"row"} gap="30px">
          <Stack width="50%">
            <div style={{ position: "relative" }}>
              {enrolled ? (
                <div style={{ position: "absolute", bottom: 20, right: 6 }}>
                  <span
                    style={{
                      backgroundColor: "#0e5794",
                      borderRadius: "10px",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#fff",
                      padding: "5px 10px",
                      position: "relative",
                      bottom: "6px",
                    }}
                  >
                    Enrolled
                  </span>
                </div>
              ) : (
                <div style={{ position: "absolute", bottom: 15, right: 10 }}>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#0e5794",
                      backgroundColor: "#fff",
                      position: "relative",
                      bottom: "6px",
                      padding: "2px 5px",
                      borderRadius: "10px",
                    }}
                  >
                    &#8377; {course?.packagedeatils?.packagePrice}
                  </span>
                </div>
              )}

              {course?.packagedeatils?.packageVideo ? (
                <video width="710" height="350" controls>
                  <source
                    src={course?.packagedeatils?.packageVideo}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  style={{
                    display: "block",
                    height: 350,
                    width: "100%",
                    objectFit: "cover",
                    marginBottom: 18,
                    borderRadius: 15,
                    cursor: "pointer",
                  }}
                  alt="Course Cover"
                  src={
                    course?.packagedeatils?.imagePath ||
                    "https://images.unsplash.com/photo-1552862750-746b8f6f7f25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGlnaHRidWxifGVufDB8fDB8fHww&w=1000&q=80"
                  }
                />
              )}
            </div>

            <div
              style={{
                position: "relative",
              }}
            >
              <Typography
                marginBottom={"15px"}
                fontSize={18}
                variant="h2"
                fontWeight={700}
                color="#0E5794"
              >
                {course?.packagedeatils.packageName}
              </Typography>

              {(course?.packagedeatils?.videoLink ||
                course?.packagedeatils?.packagepdf) && (
                <div className={styles.iconsWrapCourse}>
                  {course?.packagedeatils?.packageVideo && (
                    <a
                      href={course?.packagedeatils?.videoLink}
                      target="_blank"
                      s
                    >
                      <FaVideo />
                    </a>
                  )}
                  {course?.packagedeatils?.packagepdf && (
                    <a
                      href={course?.packagedeatils?.packagepdf}
                      target="_blank"
                    >
                      <FaFilePdf />
                    </a>
                  )}
                </div>
              )}
            </div>

            <Stack
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={"15px"}
            >
              <Typography fontSize={14} fontWeight={500} color="#5F81B3">
                {course?.packagedeatils?.courseName}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                color="#3E3E3E"
                sx={{ textDecoration: "underline" }}
              >
                {course?.neworder ? course?.neworder.length : 0} Lessons
              </Typography>
            </Stack>

            <Stack gap="10px">
              <Typography color={"#575757"} fontSize={16} fontWeight={600}>
                Description:
              </Typography>
              <Typography
                color={"#575757"}
                fontSize={16}
                fontWeight={500}
                // paddingLeft={18}
                dangerouslySetInnerHTML={{
                  __html: course?.packagedeatils?.packageDesc,
                }}
              ></Typography>
            </Stack>
          </Stack>

          <Stack flex={1}>
            <Tabs selectedTabClassName={styles.selectedTab}>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <TabList
                  className={styles.tabs}
                  style={{ width: "fit-content" }}
                >
                  <Tab className={styles.tab}>Lessons</Tab>
                </TabList>
              </Stack>

              <TabPanel>
                <Stack gap={"12px"}>
                  {course?.neworder && course?.neworder?.length
                    ? course?.neworder?.map((order, ind) => (
                        <Stack
                          border="2px solid #F0F4FF"
                          borderRadius={"15px"}
                          key={ind}
                          padding={2}
                          width={"100%"}
                          sx={enrolled ? { cursor: "pointer" } : null}
                          onClick={() => {
                            console.log("clicked ");
                            console.log("order", order);
                            if (
                              enrolled ||
                              order?.item?.package_test_mapping?.isFree
                            ) {
                              if (
                                order?.type &&
                                order?.type.toLowerCase() == "mcq"
                              )
                                navigate("/mcq-test", { state: order });
                              else if (order?.type.toLowerCase() == "media")
                                openModal("video", order?.url);
                              else if (
                                order?.type &&
                                order?.type?.toLowerCase() == "subjective"
                              )
                                navigate("/subjective-test", { state: order });
                              else if (
                                order?.type &&
                                order?.type.toLowerCase() == "pdf"
                              ) {
                                openModal("pdf", order?.url);
                              }
                            } else {
                              toast.warning(
                                "To access the course contents, enroll now."
                              );
                            }
                          }}
                        >
                          <Stack
                            width={"100%"}
                            flexDirection={"row"}
                            justifyContent={"flex-start"}
                            gap="15px"
                            alignItems={"center"}
                            position={"relative"}
                          >
                            {order?.type?.toLowerCase() == "media" &&
                            order?.media_type
                              ?.toLowerCase()
                              .includes("video") ? (
                              <PlayCircleOutlineIcon />
                            ) : (
                              <DescriptionIcon />
                            )}
                            <Typography textAlign="start">
                              {order?.type.toLowerCase() == "media" ||
                              order?.type.toLowerCase() == "pdf"
                                ? order?.item?.name
                                : order?.item?.TestName}
                            </Typography>
                            {order?.item?.package_test_mapping?.isFree && (
                              <span className={styles.freeTag}>Free</span>
                            )}
                          </Stack>
                          {order?.item?.testdescription && (
                            <Typography
                              color="#0000007f"
                              fontSize={14}
                              sx={{
                                marginLeft: 5,
                                marginTop: 2,
                                fontWeight: 600,
                              }}
                            >
                              {order?.item?.testdescription}
                            </Typography>
                          )}

                          {(order?.item?.videoLink ||
                            order?.item?.imagePath) && (
                            <div className={styles.iconsWrap}>
                              {order?.item?.videoLink && (
                                <a
                                  href={order?.item?.videoLink}
                                  target="_blank"
                                  s
                                >
                                  <FaVideo />
                                </a>
                              )}
                              {order?.item?.imagePath && (
                                <a
                                  href={order?.item?.imagePath}
                                  target="_blank"
                                >
                                  <FaFilePdf />
                                </a>
                              )}
                            </div>
                          )}
                        </Stack>
                      ))
                    : null}
                </Stack>
              </TabPanel>
            </Tabs>
          </Stack>
          <VideoAndPDFModal
            isOpen={isModalOpen}
            onClose={closeModal}
            content={modalContent}
          />
          {!enrolled ? (
            <Button
              sx={{
                borderRadius: 50,
                height: "50px",
                fontSize: 14,
                padding: "0px 60px",
                boxShadow: "none",
                backgroundColor: "#1F5972",
                margin: "0px", // Remove margin
                textTransform: "none",
                position: "fixed",
                bottom: "20px",
                right: "20px",
                zIndex: 1000, // Increase zIndex if needed
              }}
              variant="contained"
              onClick={() => {
                navigate(`/cart-checkout/${id}`);
              }}
            >
              Enroll Now
            </Button>
          ) : null}
        </Stack>
      </div>

      {/* Mobile and Small Screen Design */}
      <div className={styles.mobileView}>
        <Stack padding="20px" flexDirection={"column"} gap="20px">
          {/* Course details */}
          <Stack alignItems="center">
            <img
              style={{
                maxWidth: "100%",
                objectFit: "cover",
                borderRadius: "15px",
                cursor: "pointer",
              }}
              alt="Course Cover"
              src={
                course?.packagedeatils?.imagePath ||
                "https://images.unsplash.com/photo-1552862750-746b8f6f7f25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGlnaHRidWxifGVufDB8fDB8fHww&w=1000&q=80"
              }
            />
          </Stack>

          <Typography
            textAlign="center"
            marginBottom={"15px"}
            fontSize={18}
            variant="h2"
            fontWeight={700}
            color="#0E5794"
          >
            {course?.packagedeatils.packageName}
          </Typography>

          <Stack
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            marginBottom={"15px"}
          >
            <Typography fontSize={14} fontWeight={500} color="#5F81B3">
              {course?.packageName}
            </Typography>
            <Typography
              fontSize={14}
              fontWeight={500}
              color="#3E3E3E"
              sx={{ textDecoration: "underline" }}
            >
              {course?.neworder ? course?.neworder?.length : 0} Lessons
            </Typography>
          </Stack>

          <Stack gap="10px">
            <Typography color={"#575757"} fontSize={16} fontWeight={600}>
              Description:
            </Typography>
            <Typography
              color={"#575757"}
              fontSize={16}
              fontWeight={500}
              // paddingLeft={18}
              dangerouslySetInnerHTML={{
                __html: course?.packagedeatils?.packageDesc,
              }}
            ></Typography>
          </Stack>

          {/* Tabs for lessons */}
          <Tabs selectedTabClassName={styles.selectedTab}>
            <TabList className={styles.tabs} style={{ width: "fit-content" }}>
              <Tab className={styles.tab}>Lessons</Tab>
            </TabList>

            <TabPanel>
              <Stack gap={"12px"}>
                {course?.neworder &&
                  course?.neworder?.length &&
                  course?.neworder?.map((order, ind) => (
                    <Stack
                      key={ind}
                      padding={2}
                      width={"100%"}
                      sx={enrolled ? { cursor: "pointer" } : null}
                      onClick={() => {
                        if (enrolled) {
                          if (
                            order?.type &&
                            order?.type.toLowerCase() === "mcq"
                          )
                            navigate("/mcq-test", { state: order });
                          else if (order?.type.toLowerCase() === "media")
                            openModal("video", order.url);
                          else if (
                            order?.type &&
                            order?.type.toLowerCase() === "subjective"
                          )
                            navigate("/subjective-test", { state: order });
                          else if (
                            order?.type &&
                            order?.type.toLowerCase() === "pdf"
                          ) {
                            openModal("pdf", order.url);
                          }
                        } else {
                          toast.warning(
                            "To access the course contents, enroll now."
                          );
                        }
                      }}
                    >
                      <Stack
                        width={"100%"}
                        flexDirection={"row"}
                        justifyContent={"flex-start"}
                        gap="15px"
                        alignItems={"center"}
                      >
                        {order.type.toLowerCase() === "media" &&
                        order?.media_type?.toLowerCase().includes("video") ? (
                          <PlayCircleOutlineIcon />
                        ) : (
                          <DescriptionIcon />
                        )}
                        <Typography textAlign="start">
                          {order?.type.toLowerCase() === "media" ||
                          order?.type.toLowerCase() === "pdf"
                            ? order.item.name
                            : order.item.TestName}
                        </Typography>
                      </Stack>
                      {order?.item?.testdescription && (
                        <Typography
                          color="#0000007f"
                          fontSize={14}
                          sx={{
                            marginLeft: 5,
                            marginTop: 2,
                            fontWeight: 600,
                          }}
                        >
                          {order?.item?.testdescription}
                        </Typography>
                      )}
                    </Stack>
                  ))}
              </Stack>
            </TabPanel>
          </Tabs>
          <VideoAndPDFModal
            isOpen={isModalOpen}
            onClose={closeModal}
            content={modalContent}
          />
          {!enrolled ? (
            <Button
              sx={{
                borderRadius: 50,
                height: "50px",
                fontSize: 14,
                padding: "0px 60px",
                boxShadow: "none",
                backgroundColor: "#1F5972",
                marginTop: "10px", // Remove margin
                textTransform: "none",
                position: "fixed",
                bottom: "20px",
                right: "20px",
                zIndex: 1000, // Increase zIndex if needed
              }}
              variant="contained"
              onClick={() => {
                navigate(`/cart-checkout/${id}`);
              }}
            >
              Enroll Now
            </Button>
          ) : null}
        </Stack>
      </div>
    </div>
  );
}
// import { Button, Stack, Typography, CircularProgress, Modal } from "@mui/material";
// import React, { useContext, useEffect, useState, useRef } from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import styles from "./CourseDetails.module.css";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import DescriptionIcon from "@mui/icons-material/Description";
// import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
// import { _getCourseDetails, _getuserCourseDetails } from "../../services/courseService";
// import { toast } from "react-toastify";
// import { CoursePackageContext } from "../../Context/CoursesContext";
// import { Helmet } from "react-helmet";
// import { AuthContext } from "../../Context/UserContext";
// import { Document, Page } from 'react-pdf';

// export default function CourseDetails(props) {
//   const { userCourses } = useContext(CoursePackageContext);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [course, setCourse] = useState(location.state);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [enrolled, setEnrolled] = useState(false);
//   const [readMoreCard, setReadMoreCard] = useState(null);
//   const readMoreRef = useRef(null);
//   const [modalContent, setModalContent] = useState({ type: "", src: "" });
//   const [loading, setLoading] = useState(true); // Added loading state
//   const { user } = useContext(AuthContext);

//   const openModal = (type, src) => {
//     console.log("type",type,"src",src.item.url)
//     setModalContent({ type, src });
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   const { id } = useParams();

//   useEffect(() => {
//     MyCourseDetail(id);
//   }, []);

//   const MyCourseDetail = async (id) => {
//     try {
//       const res = await _getCourseDetails(id, user);
//       if (res.status === 200) {
//         setCourse(res.data);
//         if (localStorage.getItem("users")) {
//           const res1 = await _getuserCourseDetails(id);
//           if (res1.status === 200) {
//             if (res1.data.enrolled === true) {
//               setEnrolled(true);
//             }
//           }
//         } else {
//           setEnrolled(false);
//         }
//       }
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   if (loading) {
//     return (
//       <div className={styles.loadingContainer}>
//         <CircularProgress />
//       </div>
//     );
//   }

//   if (!course) {
//     return <div>Loading course data...</div>;
//   }

//   return (
//     <div className={styles.courseDetailsContainer}>
//       <Helmet>
//         <title>{course?.packagedeatils?.packageName} - AI Koach</title>
//         <meta name="description" content={course?.packagedetails?.packageDesc || ""} />
//       </Helmet>

//       {/* Desktop Design */}
//       <div className={styles.desktopView}>
//         <Stack padding="20px" flexDirection={"row"} gap="30px">
//           <Stack width="50%">
//             <div style={{ position: "relative" }}>
//               {enrolled ? (
//                 <div style={{ position: "absolute", bottom: 20, right: 6 }}>
//                   <span
//                     style={{
//                       backgroundColor: "#0e5794",
//                       borderRadius: "10px",
//                       fontSize: "12px",
//                       fontWeight: "600",
//                       color: "#fff",
//                       padding: "5px 10px",
//                       position: "relative",
//                       bottom: "6px",
//                     }}
//                   >
//                     Enrolled
//                   </span>
//                 </div>
//               ) : (
//                 <div style={{ position: "absolute", bottom: 15, right: 10 }}>
//                   <span
//                     style={{
//                       fontSize: "12px",
//                       fontWeight: "600",
//                       color: "#0e5794",
//                       backgroundColor: "#fff",
//                       position: "relative",
//                       bottom: "6px",
//                       padding: "2px 5px",
//                       borderRadius: "10px",
//                     }}
//                   >
//                     &#8377; {course?.packagedeatils?.packagePrice}
//                   </span>
//                 </div>
//               )}
//               <img
//                 style={{
//                   display: "block",
//                   height: 350,
//                   width: "100%",
//                   objectFit: "cover",
//                   marginBottom: 18,
//                   borderRadius: 15,
//                   cursor: "pointer",
//                 }}
//                 alt="Course Cover"
//                 src={
//                   course?.packagedeatils?.imagePath ||
//                   "https://images.unsplash.com/photo-1552862750-746b8f6f7f25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGlnaHRidWxifGVufDB8fDB8fHww&w=1000&q=80"
//                 }
//               />
//             </div>

//             <Typography
//               marginBottom={"15px"}
//               fontSize={18}
//               variant="h2"
//               fontWeight={700}
//               color="#0E5794"
//             >
//               {course?.packagedeatils.packageName}
//             </Typography>

//             <Stack
//               flexDirection={"row"}
//               justifyContent={"space-between"}
//               alignItems={"center"}
//               marginBottom={"15px"}
//             >
//               <Typography fontSize={14} fontWeight={500} color="#5F81B3">
//                 {course?.packageName}
//               </Typography>
//               <Typography
//                 fontSize={14}
//                 fontWeight={500}
//                 color="#3E3E3E"
//                 sx={{ textDecoration: "underline" }}
//               >
//                 {course?.neworder ? course?.neworder.length : 0} Lessons
//               </Typography>
//             </Stack>

//             <Stack gap="10px">
//               <Typography color={"#575757"} fontSize={16} fontWeight={600}>
//                 Description:
//               </Typography>
//               <Typography
//                 color={"#575757"}
//                 fontSize={16}
//                 fontWeight={500}
//                 paddingLeft={18}
//                 dangerouslySetInnerHTML={{ __html: course?.packagedeatils?.packageDesc }}
//               ></Typography>
//             </Stack>
//           </Stack>

//           <Stack flex={1}>
//             <Tabs selectedTabClassName={styles.selectedTab}>
//               <Stack
//                 flexDirection={"row"}
//                 alignItems={"center"}
//                 justifyContent={"space-between"}
//               >
//                 <TabList className={styles.tabs} style={{ width: "fit-content" }}>
//                   <Tab className={styles.tab}>Lessons</Tab>
//                 </TabList>
//               </Stack>

//               <TabPanel>
//                 <Stack gap={"12px"}>
//                   {course?.neworder && course?.neworder?.length
//                     ? course.neworder.map((order, ind) => (
//                       <Stack
//                         border="2px solid #F0F4FF"
//                         borderRadius={"15px"}
//                         key={ind}
//                         padding={2}
//                         width={"100%"}
//                         sx={enrolled ? { cursor: "pointer" } : null}
//                         onClick={() => {
//                           if (enrolled) {
//                             if (order?.type && order?.type.toLowerCase() === "mcq")
//                               navigate("/mcq-test", { state: order });
//                             else if (order?.type.toLowerCase() === "media")
//                               openModal("video", order);
//                             else if (
//                               order?.type &&
//                               order?.type.toLowerCase() === "subjective"
//                             )
//                               navigate("/subjective-test", { state: order });
//                             else if (
//                               order?.type &&
//                               order?.type.toLowerCase() === "pdf"
//                             ) {
//                               openModal("pdf", order);
//                             }
//                           } else {
//                             toast.warning(
//                               "To access the course contents, enroll now."
//                             );
//                           }
//                         }}
//                       >
//                         <Stack
//                           width={"100%"}
//                           flexDirection={"row"}
//                           justifyContent={"flex-start"}
//                           gap="15px"
//                           alignItems={"center"}
//                         >
//                           {order.type.toLowerCase() === "media" &&
//                             order?.media_type?.toLowerCase().includes("video") ? (
//                             <PlayCircleOutlineIcon />
//                           ) : (
//                             <DescriptionIcon />
//                           )}
//                           <Typography textAlign="start">
//                             {order?.type.toLowerCase() === "media" ||
//                               order?.type.toLowerCase() === "pdf"
//                               ? order.item.name
//                               : order.item.TestName}
//                           </Typography>
//                         </Stack>
//                         {order?.item.testdescription && (
//                           <Typography
//                             color="#0000007f"
//                             fontSize={14}
//                             sx={{
//                               marginLeft: 5,
//                               marginTop: 2,
//                               fontWeight: 600,
//                             }}
//                           >
//                             {order?.item.testdescription}
//                           </Typography>
//                         )}
//                       </Stack>
//                     ))
//                     : null}
//                 </Stack>
//               </TabPanel>
//             </Tabs>
//           </Stack>
//           {!enrolled ? (
//             <Button
//               sx={{
//                 borderRadius: 50,
//                 height: "50px",
//                 fontSize: 14,
//                 padding: "0px 60px",
//                 boxShadow: "none",
//                 backgroundColor: "#1F5972",
//                 margin: "0px",
//                 textTransform: "none",
//                 position: "fixed",
//                 bottom: "20px",
//                 right: "20px",
//                 zIndex: 1000,
//               }}
//               variant="contained"
//               onClick={() => {
//                 navigate(`/cart-checkout/${id}`);
//               }}
//             >
//               Enroll Now
//             </Button>
//           ) : null}
//         </Stack>
//       </div>

//       {/* Mobile and Small Screen Design */}
//       <div className={styles.mobileView}>
//         <Stack padding="20px" flexDirection={"column"} gap="20px">
//           {/* Course details */}
//           <Stack alignItems="center">
//             <img
//               style={{
//                 maxWidth: "100%",
//                 objectFit: "cover",
//                 borderRadius: "15px",
//                 cursor: "pointer",
//               }}
//               alt="Course Cover"
//               src={
//                 course?.packagedeatils.imagePath ||
//                 "https://images.unsplash.com/photo-1552862750-746b8f6f7f25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bGlnaHRidWxifGVufDB8fDB8fHww&w=1000&q=80"
//               }
//             />
//           </Stack>

//           <Typography
//             textAlign="center"
//             marginBottom={"15px"}
//             fontSize={18}
//             variant="h2"
//             fontWeight={700}
//             color="#0E5794"
//           >
//             {course?.packagedeatils.packageName}
//           </Typography>

//           <Stack
//             flexDirection={"row"}
//             justifyContent={"center"}
//             alignItems={"center"}
//             marginBottom={"15px"}
//           >
//             <Typography fontSize={14} fontWeight={500} color="#5F81B3">
//               {course?.packageName}
//             </Typography>
//             <Typography
//               fontSize={14}
//               fontWeight={500}
//               color="#3E3E3E"
//               sx={{ textDecoration: "underline" }}
//             >
//               {course?.neworder ? course?.neworder.length : 0} Lessons
//             </Typography>
//           </Stack>

//           <Stack gap="10px">
//             <Typography color={"#575757"} fontSize={16} fontWeight={600}>
//               Description:
//             </Typography>
//             <Typography
//               color={"#575757"}
//               fontSize={16}
//               fontWeight={500}
//               paddingLeft={18}
//               dangerouslySetInnerHTML={{ __html: course?.packagedeatils.packageDesc }}
//             ></Typography>
//           </Stack>

//           {/* Tabs for lessons */}
//           <Tabs selectedTabClassName={styles.selectedTab}>
//             <TabList className={styles.tabs} style={{ width: "fit-content" }}>
//               <Tab className={styles.tab}>Lessons</Tab>
//             </TabList>

//             <TabPanel>
//               <Stack gap={"12px"}>
//                 {course?.neworder &&
//                   course?.neworder?.length &&
//                   course.neworder.map((order, ind) => (
//                     <Stack
//                       key={ind}
//                       padding={2}
//                       width={"100%"}
//                       sx={enrolled ? { cursor: "pointer" } : null}
//                       onClick={() => {
//                         if (enrolled) {
//                           if (order?.type && order?.type.toLowerCase() === "mcq")
//                             navigate("/mcq-test", { state: order });
//                           else if (order?.type.toLowerCase() === "media")
//                             openModal("video", order);
//                           else if (
//                             order?.type &&
//                             order?.type.toLowerCase() === "subjective"
//                           )
//                             navigate("/subjective-test", { state: order });
//                           else if (
//                             order?.type &&
//                             order?.type.toLowerCase() === "pdf"
//                           ) {
//                             openModal("pdf", order);
//                           }
//                         } else {
//                           toast.warning(
//                             "To access the course contents, enroll now."
//                           );
//                         }
//                       }}
//                     >
//                       <Stack
//                         width={"100%"}
//                         flexDirection={"row"}
//                         justifyContent={"flex-start"}
//                         gap="15px"
//                         alignItems={"center"}
//                       >
//                         {order.type.toLowerCase() === "media" &&
//                           order?.media_type?.toLowerCase().includes("video") ? (
//                           <PlayCircleOutlineIcon />
//                         ) : (
//                           <DescriptionIcon />
//                         )}
//                         <Typography textAlign="start">
//                           {order?.type.toLowerCase() === "media" ||
//                             order?.type.toLowerCase() === "pdf"
//                             ? order.item.name
//                             : order.item.TestName}
//                         </Typography>
//                       </Stack>
//                       {order?.item.testdescription && (
//                         <Typography
//                           color="#0000007f"
//                           fontSize={14}
//                           sx={{
//                             marginLeft: 5,
//                             marginTop: 2,
//                             fontWeight: 600,
//                           }}
//                         >
//                           {order?.item.testdescription}
//                         </Typography>
//                       )}
//                     </Stack>
//                   ))}
//               </Stack>
//             </TabPanel>
//           </Tabs>
//           {!enrolled ? (
//             <Button
//               sx={{
//                 borderRadius: 50,
//                 height: "50px",
//                 fontSize: 14,
//                 padding: "0px 60px",
//                 boxShadow: "none",
//                 backgroundColor: "#1F5972",
//                 marginTop: "10px",
//                 textTransform: "none",
//                 position: "fixed",
//                 bottom: "20px",
//                 right: "20px",
//                 zIndex: 1000,
//               }}
//               variant="contained"
//               onClick={() => {
//                 navigate(`/cart-checkout/${id}`);
//               }}
//             >
//               Enroll Now
//             </Button>
//           ) : null}
//         </Stack>
//       </div>
//       {/* PDF Viewer Modal */}
//       <Modal open={isModalOpen} onClose={closeModal}>
//         <div style={{ width: "80vw", height: "80vh", margin: "auto", overflow: "auto" }}>
//           <Document file={modalContent.src}>
//             <Page pageNumber={1} />
//           </Document>
//         </div>
//       </Modal>
//     </div>
//   );
// }
