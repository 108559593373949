import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import "./App.css";
import Homepage from "./Screens/Home/Homepage";
import { Fragment, useContext } from "react";
import Navbar from "./Components/Navbar";
import NavMobile from "./Components/Nav/MobileNav"
import Courses from "./Screens/Courses/Courses";
import CourseDetails from "./Screens/Courses/CourseDetails";
import Login from "./Screens/Auth/Login";
import Signup from "./Screens/Auth/Signup";
import { CoursePackageProvider } from "./Context/CoursesContext";

import ForgotPassword from "./Screens/Auth/ForgotPassword";
import { AuthContext, AuthProvider } from "./Context/UserContext";
import Notes from "./Screens/Notes/Notes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubjectiveTest from "./Screens/Courses/SubjectiveTest";
import MCQScreen from "./Screens/Courses/MCQScreen";
import MaatSaab from "./Screens/MaatSaab/MaatSaab";
import Cart from "./Screens/Cart/Cart";
import Checkout from "./Screens/Cart/Checkout";
import PrivacyPolicy from "./Screens/Legal/PrivacyPolicy";
import TermsConditions from "./Screens/Legal/TermsConditions";
import RefundPolicy from "./Screens/Legal/RefundPolicy";
import ShippingPolicy from "./Screens/Legal/ShippingPolicy";
import AboutUs from "./Screens/Legal/Aboutus";
import ContactDetails from"./Screens/Legal/contactus";
import OTP from "./Screens/Auth/OTP";
import Loader from "./Components/Loader";
const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial, sans-serif", // Specify the font-family here
  },
});

function App() {
  const routes = [
    {
      name: "SignUp",
      key: "signup",
      component: <Signup />,
      display: localStorage.getItem("users") ? false : true,
      route: "/signup",
    },
    {
      name: "Login",
      key: "login",
      component: <Login />,
      display: localStorage.getItem("users") ? false : true,
      route: "/login",
    },
    {
      name: "Reset Password",
      key: "reset",
      component: <ForgotPassword />,
      display: localStorage.getItem("users") ? false : true,
      route: "/reset",
    },
    {
      name: "OTP",
      key: "otp",
      component: <OTP />,
      display: localStorage.getItem("users") ? false : true,
      route: "/otp",
    },
    {
      name: "Home",
      key: "home",
      component: <Homepage />,
      display: true,
      route: "/home",
    },
    {
      name: "Courses",
      key: "courses",
      component: <Courses />,
      // display: localStorage.getItem("users") ? true : false,
      display:true,

      route: "/courses",
    },
    {
      name: "Course Details",
      key: "course-details",
      component: <CourseDetails />,
      display:  true,
      route: "/course/details/:id",
    },
    {
      name: "Cart",
      key: "cart",
      component: <Cart />,
      display: localStorage.getItem("users") ? true : false,
      route: "/cart-checkout/:id",
    },
    {
      name: "Checkout",
      key: "checkout-status",
      component: <Checkout />,
      display: localStorage.getItem("users") ? true : false,
      route: "/checkout-status",
    },
    {
      name: "Privacy Policy",
      key: "privacy-policy",
      component: <PrivacyPolicy />,
      display: true,
      route: "/privacy-policy",
    },
    {
      name: "Terms & Conditions",
      key: "terms-conditions",
      component: <TermsConditions />,
      display: true,
      route: "/terms-and-conditions",
    },
    {
      name: "Refund Policy",
      key: "refund-policy",
      component: <RefundPolicy />,
      display: true,
      route: "/refund-policy",
    },
    {
      name: "Shipping Policy",
      key: "shipping-policy",
      component: <ShippingPolicy />,
      display: true,
      route: "/shipping-policy",
    }, {
      name: "Aboutus",
      key: "Aboutus",
      component: <AboutUs />,
      display: true,
      route: "/aboutus",
    },
    {
      name: "contactus",
      key: "contactus",
      component: <ContactDetails />,
      display: true,
      route: "/contactus",
    },
    {
      name: "MaatSaab",
      key: "maatsaab",
      component: <MaatSaab />,
      display: true,
      route: "/maatsaab",
    },
    {
      name: "Subjective Test",
      key: "subjective-test",
      component: <SubjectiveTest />,
      display: localStorage.getItem("users") ? true : false,
      route: "/subjective-test",
    },
    {
      name: "MCQ Test",
      key: "mcq-test",
      component: <MCQScreen />,
      display: localStorage.getItem("users") ? true : false,
      route: "/mcq-test",
    },
    {
      name: "Root",
      key: "root",
      component: (
        <Navigate to="/home" replace />
      ),
      display: true,
      route: "/",
    },
  ];
  const [isMobile, setIsMobile] = useState(false);

  const checkIsMobile = () => {
    if (window.innerWidth <= 768) { // Change 768 to your desired breakpoint
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Add event listener on component mount
  useEffect(() => {
    checkIsMobile(); // Check initial screen size
    const handleResize = () => {
      checkIsMobile(); // Check screen size on resize
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener on component unmount
    };
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.route && route.display) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }
      return null;
    });
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <CoursePackageProvider>
          <nav className={isMobile ? 'mobile-nav' : 'desktop-nav'}>
      {/* Conditionally render mobile or desktop navigation */}
      {isMobile ? (
        <NavMobile />
      ) : (
        <Navbar />
      )}
    </nav>

          
            
            <div style={{ marginTop: 90 }}>
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="*"
                  element={
                    localStorage.getItem("users") ? (
                      <Navigate to="/home" />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
              </Routes>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
                limit={1}
              />
            </div>
          </CoursePackageProvider>
        </AuthProvider>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
