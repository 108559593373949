import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Helper from "../../utils/helper";
import { _signIn } from "../../services/authService";
import { AuthContext } from "../../Context/UserContext";
import OTPInput from "otp-input-react";
import { verifyOtp } from "../../services/authService";
export default function OTP() {
  const [otp, setOtp] = useState();
  const [number, setNumber] = useState(null);
  const validPhone = (num) => {
    return num.length <= 10 && !isNaN(num);
  };
  const { setUser, setLoading } = useContext(AuthContext);
  const navigate = useNavigate();
  const otpHandler = async () => {
    let payload = {
      mobileNumber: number,
      OTP: otp,
    };
    if (
      !payload?.mobileNumber ||
      !validPhone(payload?.mobileNumber) ||
      !Helper.isValidMobileNumber(payload?.mobileNumber)
    ) {
      toast.error("Please enter valid phone number");
    } else if (!otp || otp.toString()?.length < 6) {
      toast.error("Invalid OTP");
    } else {
      try {
        setLoading(true);
        let response = await verifyOtp(payload);
        if (response.status == 200) {
          Helper.setLocalStorageItem("users", response.data);
          setUser(response.data?.user);
          setLoading(false);
          toast.success("Signup Successful");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return;
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    }
  };
  useEffect(() => {
    let details = Helper.getLocalStorageItem("otpDetails");
    if (!details || !details?.mobileNumber) {
      // navigate("/home");
    } else {
      setNumber(details?.mobileNumber);
      localStorage.removeItem("otpDetails");
    }
  }, []);
  return (
    <Stack
      sx={{
        boxShadow:
          "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
        padding: "20px",
        borderRadius: "15px",
        width: "460px",
        margin: "30px auto",
        marginTop: 14,
      }}
    >
      <Typography
        fontSize={26}
        color="#1F5972"
        fontWeight={600}
        marginBottom={6}
        textAlign={"left"}
      >
        <strong>OTP Verification</strong>
        <br />
        Enter the verification code we just sent on your mobile number {number}.
      </Typography>

      <Stack gap="20px">
        <OTPInput
          OTPLength={6}
          onChange={(e) => {
            setOtp(e);
          }}
          otpType="number"
          value={otp}
          inputStyles={{ marginRight: 0, width: "45px", height: "45px" }}
          style={{ justifyContent: "space-between" }}
        />
        <Button
          sx={{
            borderRadius: "6px",
            height: "50px",
            fontSize: 14,
            padding: "0px 60px",
            boxShadow: "none",
            backgroundColor: "#1F5972",
            margin: "0px auto",
            textTransform: "none",
            width: "100%",
          }}
          onClick={otpHandler}
          variant="contained"
        >
          Verify OTP
        </Button>
      </Stack>
    </Stack>
  );
}
