import Client from "../api/client";
import Helper from "../utils/helper";

export const _signup = async (obj) => {
  return Client.post("user-signup", obj);
};
export const verifyOtp = async (obj) => {
  return Client.post("verifyOtp", obj);
};
export const _signIn = async (mobileNumber, password) => {
  // alert('hehe')
  // let VToken = await AsyncStorage.getItem('Token');
  // VToken = JSON.parse(VToken);
  const data = {
    mobileNumber,
    password,
  };
  return Client.post("user-login", data);
};
export const resendOTP = async (mobileNumber) => {
  return Client.post(`resend-otp/${mobileNumber}`);
};

export const whatsappApi = async (mobileNumber, planId) => {
  return Client.post(`sendmessage`, {
    mobileNumber,
    planId,
  });
};

export const resetPassword = async (payload) => {
  return Client.post(`forgetpassword`, payload);
};

export const reevaluate = async (payload) => {
  return Client.post(`updatesubjectanswersubmit`, payload, {
    headers: {
      Authorization: `Bearer ${Helper.getLocalStorageItem("users")?.token}`,
    },
  });
};
