import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Box, Grid } from "@mui/material";
import AllCourses from "./AllCourses";
import UserCourses from "./UserCourses";
import { CoursePackageContext } from "../../Context/CoursesContext";
import { AuthContext } from "../../Context/UserContext";
import { _getCoursePackages , _getAllCourses } from "../../services/courseService";
import styles from "./Courses.module.css";

export default function Courses() {
  const { CoursePackage, setCoursePackage, userCourses } = useContext(CoursePackageContext);
  const { user, setLoading } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [myCourse, setMyCourse] = useState([]);
// console.log('@@' ,CoursePackage )
  const updateAllCourses = async () => {
    try {
      setLoading(true);
      const res = await _getAllCourses();
      if (res.status === 200) {
        setLoading(false);
        setCoursePackage(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (user && !CoursePackage?.length) {
      updateAllCourses();
    }
  }, [user]); // Add user to the dependency array

  useEffect(() => {
    // Map userCourses only if it exists and is not empty
    if (userCourses && userCourses.length > 0) {
      setMyCourse(userCourses?.map(course => course?.package));
    }
  }, [userCourses]); // Add userCourses to the dependency array

  return (
    <Box sx={{ padding: "20px" }}>
      <Tabs selectedIndex={currentPage} onSelect={index => setCurrentPage(index)}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          marginBottom={2}
        >
          <Grid item xs={12} md={6} padding={2}>
            <TabList className={styles.tabs} style={{ width: "fit-content" }}>
              <Tab>All Courses</Tab>
              {user?.token && (
                <Tab>My Courses</Tab>
              )}
            </TabList>
          </Grid>
        </Grid>

        {currentPage === 0 && (
          <AllCourses
            // allCourses={CoursePackage}
          />
        )}

        {currentPage === 1 && user?.token && (
          <UserCourses
            userCourses={myCourse}
          />
        )}
      </Tabs>
    </Box>
  );
}
