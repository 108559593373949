import React from "react";
import { Stack, Typography } from "@mui/material";

const FeatureItem = ({ icon, label, value }) => {
  return (
    <Stack  direction="row" alignItems="left" justifyContent={'left'}>
      <Stack
        width={60}
        height={60}
        borderRadius={"50%"}
        backgroundColor="#6065DA"
        alignItems={"center"}
        justifyContent={"center"}
        margin={'5px'}
      >
        {icon}
      </Stack>
      <Stack >
        <Typography fontSize={24} fontWeight={600} color="#575757">
          {value}
        </Typography>
        <Typography fontSize={18} color="#9D9D9D">
          {label}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FeatureItem;
