const getLocalStorageItem = (param) => {
  let val = localStorage.getItem(param);
  if (val) {
    return JSON.parse(val);
  } else {
    return null;
  }
};

const setLocalStorageItem = (param, payload) => {
  if (param && payload) {
    localStorage.setItem(param, JSON.stringify(payload));
  }
};
const validPhone = (num) => {
  return num.length > 0 && num.length <= 10 && !isNaN(num);
};
const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return email.length && re.test(email);
};
const isValidMobileNumber = (mobileNumber) => {
  // Validate the mobile number (e.g., 10 digits)
  return /^[0-9]{10}$/.test(mobileNumber);
};

const Helper = {
  getLocalStorageItem,
  setLocalStorageItem,
  validateEmail,
  validPhone,
  isValidMobileNumber,
};

export default Helper;
