import axios from "axios";
import { Config } from "../api/config";
import Helper from "../utils/helper";
import Client from "../api/client";

export const checkPaymentStatus = async (id) => {
  console.log("🚀 ~ checkPaymentStatus ~ id:", id,Config.API_URL_Pay)
  try {
    let response = await axios.get(
      Config.API_URL_Pay + `/get-payment-status?transactionId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${Helper.getLocalStorageItem("users")?.token}`,
        },
      }
    );
    return response;
    //  console.log("🚀 ~ file: client.js:59 ~ const_getCoursePackages= ~ res:", res.data)
  } catch (err) {
    console.log(err);
  }
};
